export class Job {
    jobId: string | undefined;
    name: string | undefined;
    requester: string | undefined;
    status: string | undefined;
    researcherGroup: string | undefined;
    study: string | undefined;
    participantId: string | undefined;
    participantName: string | undefined;
    timestamp: number | undefined;
    startTimestamp: number | undefined;
    endTimestamp: number | undefined;
    fileLocation: string | undefined;
  }
  