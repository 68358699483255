import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ParticipantEventsComponent } from './participant-events/participant-events.component';
import { MostRecentEventsComponent } from './most-recent-events/most-recent-events.component';
import { ParticipantActivitiesComponent } from './participant-activities/participant-activities.component';
import { ParticipantSummaryComponent } from './participant-summary/participant-summary.component';

import { StudyEventsComponent } from './study-events/study-events.component';
import { StudyActivitiesComponent } from './study-activities/study-activities.component';
import { DataExtractJobComponent } from './data-extract-job/data-extract-job.component';
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuard } from './guards/role.guard';
import { SleepLogComponent } from './sleep-log/sleep-log.component';
import { SleepSensorMeqLogComponent } from './sleep-sensor-meq-log/sleep-sensor-meq-log.component';
import { DemographicsSummaryComponent } from './demographics-summary/demographics-summary.component';
import { OutcomeMeasurementComponent} from './outcome-measurement/outcome-measurement.component';
import { StudyTeamVisitsComponent } from './study-team-visits/study-team-visits.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'participant-events',
    component: ParticipantEventsComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'study-events',
    component: StudyEventsComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'mostrecent',
    component: MostRecentEventsComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'participant-summary',
    component: ParticipantSummaryComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'participant-activities',
    component: ParticipantActivitiesComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'study-activities',
    component: StudyActivitiesComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'data-extract-job',
    component: DataExtractJobComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'demographics-summary',
    component: DemographicsSummaryComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'sleep-log',
    component: SleepLogComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'sleep-sensor-meq-log',
    component: SleepSensorMeqLogComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'outcome-measurement',
    component: OutcomeMeasurementComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'study-team-visits',
    component: StudyTeamVisitsComponent,
    data: {
      roles: ['Administrator', 'Researcher', 'Coordinator', 'Participant']
    },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    // Needed for hash routing
    path: 'code',
    component: HomeComponent
  },
];

const isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Remove this line to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
