import { formatDate } from '@angular/common';
import {AfterViewInit, Component, ViewChild, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Participant } from '../models/participant';
import { Researcher } from '../models/researcher';
import { MostRecentSensorEvent } from '../models/sensorEvent';
import { Study } from '../models/study';
import { LampService } from '../services/lamp.service';

@Component({
  selector: 'app-most-recent-events',
  templateUrl: './most-recent-events.component.html',
  styleUrls: ['./most-recent-events.component.scss']
})

export class MostRecentEventsComponent implements OnInit, AfterViewInit {

  researchers: Researcher[] | undefined;
  selectedResearcher: Researcher | undefined;
  studies: Study[] | undefined;
  selectedStudy: Study | undefined;
  participants: Participant[] | undefined;
  selectedParticipant: Participant | undefined;

  loading: boolean = false;

  @ViewChild(MatSort) sort: MatSort| undefined;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
        this.dataSource.sort = sort;
    }
  }
  displayedColumns = ['sensor','timestamp', 'datavalue'];
  dataSource: MatTableDataSource<MostRecentSensorEvent> = new MatTableDataSource;
  sensorEvents: MostRecentSensorEvent[] | undefined;

  showTable: boolean = false;
  normalize: boolean = false;
  date: Date = new Date();

  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar) { }

  

  ngOnInit(): void {

    this.service.getResearchers().subscribe(researchers => {
      this.researchers = researchers; 
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort!;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'eventDate': return new Date(item.eventDate);
        default: return item[property];
      }
    };
  }

  researcherChanged() {

    this.service.getStudiesByResearcher(this.selectedResearcher?.researcherId!).subscribe(studies => {
      this.studies = studies;
    });

    this.selectedStudy = undefined;
    this.participants = undefined;
    this.selectedParticipant = undefined;
  }

  studyChanged() {
    this.participants = undefined;
    this.selectedParticipant = undefined;    
    this.service.getParticipantsByStudy(this.selectedStudy?.studyId!).subscribe(participants => {
      this.participants = participants;
    });
  }

  participantChanged() {
    this.dataSource.data = [];
    this.showTable = false;  
  }

  generateTable() {
    var lastVal: number = -1;
    this.dataSource.data = [];
    this.loading = true;
    this.showTable = false;
    this.service.getMostRecentEventsByParticipant(this.selectedParticipant?.participantId!).subscribe({
      next: events => {
        this.sensorEvents = events;
        this.dataSource.data = this.sensorEvents;
        this.dataSource.sort = this.sort!;
        this.loading = false;
        this.showTable = true;
      },
      error: err => {
        this.loading = false;
        this.showTable = false;
        this.openSnackBar("Error Loading Table Data", "Close");
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
