import {Injectable} from "@angular/core";
import {LazyMapsAPILoaderConfigLiteral} from "@agm/core";
import { AppConfigService } from './app-config.service';

@Injectable()
export class MapsConfig implements LazyMapsAPILoaderConfigLiteral{
  public apiKey: string
  constructor() {
    this.apiKey = AppConfigService.settings.Google_API;
  }
}