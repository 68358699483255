import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts/lib/base-chart.directive';
import { Color } from 'ng2-charts/lib/color';
import { ChartData } from '../models/chartData';
import { Participant } from '../models/participant';
import { Researcher } from '../models/researcher';
import { GPSSensorEvent, DoubleSensorEvent } from '../models/sensorEvent'
import { Study } from '../models/study';
import { LampService } from '../services/lamp.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as pluginChartAnnotation from 'chartjs-plugin-annotation';
import { MatDialog } from '@angular/material/dialog';
import { ParticipantEventsDetailComponent } from './participant-events-detail/participant-events-detail.component';
import { MatTableDataSource } from '@angular/material/table';
import { Demographics } from '../models/demographics';
@Component({
  selector: 'app-participant-events',
  templateUrl: './participant-events.component.html',
  styleUrls: ['./participant-events.component.scss']
})
export class ParticipantEventsComponent implements OnInit {

  researchers: Researcher[] | undefined;
  selectedResearcher: Researcher | undefined;
  studies: Study[] | undefined;
  selectedStudy: Study | undefined;
  participants: Participant[] | undefined;
  selectedParticipant: Participant | undefined;
  sensors: string[] = ["Steps", "Heart_Rate", "Sleep", "Telephony", "Screen_State", "Accelerometer", "GPS", "Analytics", "Demographics"];
  selectedSensor: string = "Steps";
  selectedSensorUnits: string = "";
  chartTypes: string[] = ["Bar", "Line"];
  selectedType: string = "Bar";
  chartData: ChartDataSets[] | undefined;
  
  startDate: Date = new Date();
  endDate: Date = new Date();

  loading: boolean = false;
  
  customValue : number;
  customDate: Date = new Date();

  customSensorSelected: boolean = false;

  isJHU: boolean | undefined;
  demographicsInfo: Demographics | undefined;
  //used for GPS
  geolocations: GPSSensorEvent[] | undefined;
  displayedColumns = ['latitude', 'longitude', 'timestamp', 'customColumn'];
  dataSource: MatTableDataSource<GPSSensorEvent> = new MatTableDataSource;
  calculatedMeanValue = 0;

  chartLabels!: Label[];
  chartOptions = undefined;
  AllChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
         display: true,
         maxBarThickness: 70,
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          //labelString: 'Score'
        },
        ticks: {
          min: 0,
          beginAtZero: true
        }
      }]
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        clamp: true,
        color: 'black',
        font: {
          size: 12,
          weight: 'bold'
        },
        lineTension: 0,
        display: function (context) {
          return context.dataset.data[context.dataIndex] != null; 
        }
      }
    }
  };
  ScreenStateOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
         display: true,
         maxBarThickness: 70,
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          //labelString: 'Score'
        },
        ticks: {
          min: 0,
          beginAtZero: true
        }
      }]
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    annotation: {
      // Defines when the annotations are drawn.
      // This allows positioning of the annotation relative to the other
      // elements of the graph.
      //
      // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
      // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
      drawTime: 'afterDatasetsDraw', // (default)

      // Mouse events to enable on each annotation.
      // Should be an array of one or more browser-supported mouse events
      // See https://developer.mozilla.org/en-US/docs/Web/Events
      events: ['click'],

      // Double-click speed in ms used to distinguish single-clicks from 
      // double-clicks whenever you need to capture both. When listening for
      // both click and dblclick, click events will be delayed by this
      // amount.
      dblClickSpeed: 350, // ms (default)

      // Array of annotation configuration objects
      // See below for detailed descriptions of the annotation options
      annotations: [{
          drawTime: 'afterDraw', // overrides annotation.drawTime if set
          id: 'a-line-1', // optional
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: this.calculatedMeanValue,
          borderColor: 'red',
          borderWidth: 2,
          borderDash: [5, 5], // Set the pattern for dashed line (5 pixels dashed, 5 pixels gap)
          label: {
            enabled: true,
            content: 'Mean: ' + this.calculatedMeanValue.toFixed(2), // Display the mean value as label
            position: 'left', // Adjust label position
            backgroundColor: 'black', // Label background color
            fontSize: 12, // Label font size
          },
          // Fires when the user clicks this annotation on the chart
          // (be sure to enable the event in the events array below).
          onClick: function(e) {
              // `this` is bound to the annotation element
          }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        clamp: true,
        color: 'black',
        font: {
          size: 12,
          weight: 'bold'
        },
        lineTension: 0,
        display: function (context) {
          return context.dataset.data[context.dataIndex] != null; 
        }
      }
    }
  };
  AcceleroMeterChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
         display: false,
      }],
    },
    plugins: {
      datalabels: {
          display: false,
      },
    } 
  };
  chartColors: Color[] = [];
  chartLegend = true;
  chartPlugins = [pluginDataLabels, pluginChartAnnotation,
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 20; // must use `function` and not => because of `this`
        };
      }
    }];

  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar, private dialog: MatDialog) {
    this.chartColors = service.getChartColors();
  }

  ngOnInit(): void {

    this.service.getResearchers().subscribe(researchers => {
      this.researchers = researchers; 
    });
  }

  researcherChanged() {
    this.selectedStudy = undefined;
    this.studies= undefined;
    this.selectedParticipant = undefined;
    this.participants = undefined;
    this.chartData = undefined;
    this.demographicsInfo=undefined;
    this.service.getStudiesByResearcher(this.selectedResearcher?.researcherId!).subscribe(studies => {
      this.studies = studies;
    });

  }

  studyChanged() {
    this.participants = undefined;
    this.selectedParticipant = undefined;
    this.chartData = undefined;
    this.demographicsInfo=undefined;
    this.service.getParticipantsByStudy(this.selectedStudy?.studyId!).subscribe(participants => {
      this.participants = participants;
    });

  }

  participantChanged() {
    this.resetChart();
    this.verifyCustomSensorSelection();
    this.service.getFirstSensorEventByParticipant(this.selectedParticipant?.participantId!,this.selectedSensor.toLowerCase()).subscribe({
      next: events => {
        this.startDate= new Date(events.timestamp);
      },
      error: err => {
        this.startDate = new Date();
      }
    });
    this.service.getLastSensorEventByParticipant(this.selectedParticipant?.participantId!,this.selectedSensor.toLowerCase()).subscribe({
      next: events => {
        this.endDate= new Date(events.timestamp);
      },
      error: err => {
        this.endDate = new Date();
      }
    });

  }

  verifyCustomSensorSelection() {

    var supportedCustomSensors: string[] = ["Steps", "Heart_Rate", "Sleep"];

    if (supportedCustomSensors.includes(this.selectedSensor) == false) {
      this.customSensorSelected = false;
    } else {
      this.customSensorSelected = true;
    }
  }
  
  sensorChanged() {
    this.customDate = this.endDate;
    this.customValue = undefined;
    this.verifyCustomSensorSelection();
    this.selectedType="Bar";
    switch (this.selectedSensor) {
      case "Sleep":
           this.selectedSensorUnits="Hours";
           break;
      case "Telephony":
           this.selectedSensorUnits="Minutes";
           break;
      case "Screen_State":
           this.selectedSensorUnits="Minutes";
           break;
      case "Accelerometer":
           this.selectedSensorUnits="";
           this.selectedType="Line";
           break;
      default:
           this.selectedSensorUnits="";
           break;
    }
    if (this.selectedParticipant != undefined) {
      this.service.getFirstSensorEventByParticipant(this.selectedParticipant?.participantId!,this.selectedSensor.toLowerCase()).subscribe({
        next: events => {
          this.startDate= new Date(events.timestamp);
        },
        error: err => {
          this.startDate = new Date();
        }
      });
      this.service.getLastSensorEventByParticipant(this.selectedParticipant?.participantId!,this.selectedSensor.toLowerCase()).subscribe({
        next: events => {
          this.endDate= new Date(events.timestamp);
        },
        error: err => {
          this.endDate = new Date();
        }
      });
    }
    this.resetChart();
  }

  resetChart() {
    this.chartData = undefined;
    this.geolocations = undefined;
    this.demographicsInfo=undefined;
  }

  verifyDates() {

    try {
      if (!this.startDate) {
        this.startDate = new Date();
      }

      if (!(this.endDate >= this.startDate)) {
        this.endDate = this.startDate
      }
    } catch (e) {

    }

    this.resetChart();
  }

  verifyCustomDate() {

    try {
      if (!this.customDate) {
        this.customDate = new Date();
      }
   
    } catch (e) {

    }

    this.resetChart();
  }
  
  numericOnly(event): boolean {    
    let patt = /^-?[0-9]*(\.[0-9]{0,2})?$/;
    let result = patt.test(event.key);
    return result;
  }

  addSensorValue() { 

    if (this.customValue === undefined ) {
      this.openSnackBar("Please enter valid sensor value", "Close");
      return;
    }
 

    this.loading = true;
    this.chartData = undefined;
    this.service.addSensorEventsByParticipant(this.selectedParticipant?.participantId!, this.selectedSensor.toLowerCase(), this.customDate, this.customValue).subscribe({
      next: events => {
        this.loading = false;
        this.generateGraph();
      },
      error: err => {
        this.loading = false;
        this.openSnackBar("No Data On This Date Range", "Close");
      } 

    });
  }

  goToLink(latitude: number, longitude: number) {
    window.open("http://maps.google.com/?q="+latitude+","+longitude, "_blank");
  }

  generateGraph() {  
    this.loading = true;
    this.chartData = undefined;
    this.geolocations = undefined;
    this.chartOptions = this.AllChartOptions;

    switch (this.selectedSensor) {

      case "Steps":
        this.service.getStepSensorEventsByParticipant(this.selectedParticipant?.participantId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
          next: events => {

            this.chartData = new Array<ChartDataSets>();
            this.chartLabels = new Array<string>();

            var chartData = new ChartData();
            var total = 0;

            chartData.data = new Array<number>();

            events.forEach(event => {
              chartData.data!.push(event.value!);

              total += event.value!;

              this.chartLabels.push(event.label);
            });

            chartData.label = this.selectedSensor + ' (' + total.toString() + ')';

            this.chartData.push(chartData);

            this.loading = false;
          },
          error: err => {
            this.chartData = undefined;
            this.loading = false;
            this.openSnackBar("No Data On This Date Range", "Close");
          }
        });
        break;

      case "Heart_Rate":
        this.service.getHeartRateSensorEventsByParticipant(this.selectedParticipant?.participantId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
          next: events => {
              this.chartData = new Array<ChartDataSets>();
              this.chartLabels = new Array<string>();
              
              var minData = new ChartData();
              var avgData = new ChartData();
              var maxData = new ChartData();

              minData.label = this.selectedSensor + ' (Min)';
              avgData.label = this.selectedSensor + ' (Average)';
              maxData.label = this.selectedSensor + ' (Peak)';

              minData.data = new Array<number>();
              avgData.data = new Array<number>();
              maxData.data = new Array<number>();

              events.forEach(event => {

                if (event.min == null) {
                  minData.data!.push(null);
                } else {
                  minData.data!.push(Math.round(event.min! + Number.EPSILON));
                }


                if (event.value == null) {
                  avgData.data!.push(null);
                } else {
                  avgData.data!.push(Math.round(event.value + Number.EPSILON));
                }

                if (event.max == null) {
                  maxData.data!.push(null);
                } else {
                  maxData.data!.push(Math.round(event.max! + Number.EPSILON));
                }

                this.chartLabels.push(event.label);
              });

              this.chartData.push(minData);
              this.chartData.push(avgData);
              this.chartData.push(maxData);

              this.loading = false;
            },
            error: err => {
              this.chartData = undefined;
              this.loading = false;
              this.openSnackBar("No Data On This Date Range", "Close");
            }
          });
        break;

        case "Sleep":
          this.service.getSleepSensorEventsByParticipant(this.selectedParticipant?.participantId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
            next: events => {
  
              this.chartData = new Array<ChartDataSets>();
              this.chartLabels = new Array<string>();
  
              var chartData = new ChartData();
              var total = 0;
  
              chartData.data = new Array<number>();
  
              events.forEach(event => {


                if (event.value == null) {
                  chartData.data!.push(null);
                } else {
                  chartData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);  
                  total += event.value!;
                }
 
                this.chartLabels.push(event.label);
              });
              total = Math.round((total + Number.EPSILON) * 100) / 100;
  
              chartData.label = this.selectedSensor + ' (' + total.toString() + ')';
  
              this.chartData.push(chartData);
  
              this.loading = false;
            },
            error: err => {
              this.chartData = undefined;
              this.loading = false;
              this.openSnackBar("No Data On This Date Range", "Close");
            }
          });
          break;

        case "Telephony":
          this.service.getTelephonySensorEventsByParticipant(this.selectedParticipant?.participantId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
            next: events => {

              this.chartData = new Array<ChartDataSets>();
              this.chartLabels = new Array<string>();

              var chartData = new ChartData();
              var total = 0;

              chartData.data = new Array<number>();

              events.forEach(event => {

                if (event.value == null) {
                  chartData.data!.push(null);
                } else {
                  chartData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);  
                  total += event.value!;
                }

                this.chartLabels.push(event.label);
              });
              total = Math.round((total + Number.EPSILON) * 100) / 100;
              chartData.label = this.selectedSensor + ' (' + total.toString() + ')';

              this.chartData.push(chartData);

              this.loading = false;
            },
            error: err => {
              this.chartData = undefined;
              this.loading = false;
              this.openSnackBar("No Data On This Date Range", "Close");
            }
          });
          break;

        case "Screen_State":
          this.service.getScreenStateSensorEventsByParticipant(this.selectedParticipant?.participantId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
            next: events => {

              this.chartData = new Array<ChartDataSets>();
              this.chartLabels = new Array<string>();

              var chartData = new ChartData();
              var total = 0;

              chartData.data = new Array<number>();

              var count = 0;
              events.forEach(event => {

                if (event.value == null) {
                  chartData.data!.push(null);
                } else {
                  chartData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);  
                  total += event.value!;
                }

                count = count + 1;
                this.chartLabels.push(event.label);
              });
              total = Math.round((total + Number.EPSILON) * 100) / 100;
              this.calculatedMeanValue = total/count;
              this.ScreenStateOptions.annotation.annotations[0].value = this.calculatedMeanValue;
              this.ScreenStateOptions.annotation.annotations[0].label.content = this.calculatedMeanValue.toFixed(2).toString();
              this.chartOptions = this.ScreenStateOptions;
              
              chartData.label = this.selectedSensor + ' (' + total.toString() + ')';

              this.chartData.push(chartData);

              this.loading = false;
            },
            error: err => {
              this.chartData = undefined;
              this.loading = false;
              this.openSnackBar("No Data On This Date Range", "Close");
            }
          });
          break;
        case "Accelerometer":
          this.service.getXYZSensorEventsByParticipant(this.selectedParticipant?.participantId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
            next: events => {

              this.chartData = new Array<ChartDataSets>();
              this.chartLabels = new Array<string>();

              var XData = new ChartData();
              var YData = new ChartData();
              var ZData = new ChartData();
              XData.label = this.selectedSensor + ' X';
              YData.label = this.selectedSensor + ' Y';
              ZData.label = this.selectedSensor + ' Z';

              XData.data = new Array<number>();
              YData.data = new Array<number>();
              ZData.data = new Array<number>();

             
              events.forEach(event => {
                if (event.x == null) {
                  XData.data!.push(null);
                } else {
                  XData.data!.push(Math.round((event.x! + Number.EPSILON) * 1000) / 1000);
                }

                if (event.y == null) {
                  YData.data!.push(null);
                } else {
                  YData.data!.push(Math.round((event.y! + Number.EPSILON) * 1000) / 1000);
                }

                if (event.z == null) {
                  ZData.data!.push(null);
                } else {
                  ZData.data!.push(Math.round((event.z! + Number.EPSILON) * 1000) / 1000);
                }

                this.chartLabels.push(this.service.convertTimestamptoTime(event.timestamp));
              });
              this.chartOptions = this.AcceleroMeterChartOptions;
              var charts = new Array<any>();
              charts.push({
                data: XData.data,
                label: XData.label,
                type: 'line',
                fill: false,
                lineTension: 0.1,
                participant: this.selectedParticipant.participantId,
                action: "sensor",
                actionType: "accelerometer"
              });
              charts.push({
                data: YData.data,
                label: YData.label,
                lineTension: 0.1,
                type: 'line',
                fill: false,
                participant: this.selectedParticipant.participantId,
                action: "sensor",
                actionType: "accelerometer"
              });
              charts.push({
                data: ZData.data,
                label: ZData.label,
                type: 'line',
                fill: false,
                participant: this.selectedParticipant.participantId,
                action: "sensor",
                actionType: "accelerometer"
              });
 
              this.chartData = new Array<ChartDataSets>();
              charts.forEach(chart => this.chartData!.push(chart));

              this.loading = false;
            },
            error: err => {
              this.chartData = undefined;
              this.loading = false;
              this.openSnackBar("No Data On This Date Range", "Close");
            }
          });
          break;
        case "GPS":
          this.service.getGPSSensorEventsByParticipant(this.selectedParticipant?.participantId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
            next: events => {
              this.geolocations=events;
              this.dataSource.data = events;
              this.loading = false;
            },
            error: err => {
              this.chartData = undefined;
              this.geolocations = undefined;
              this.loading = false;
              this.openSnackBar("No Data On This Date Range", "Close");
            }
          });
            break;
        case "Analytics":
          this.service.getAnalyticsSensorEventsByParticipant(this.selectedParticipant?.participantId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
            next: events => {
  
              this.chartData = new Array<ChartDataSets>();
              this.chartLabels = new Array<string>();
  
              var chartData = new ChartData();
              var total = 0;
  
              chartData.data = new Array<number>();
  
              events.forEach(event => {
                chartData.data!.push(event.value!);
  
                total += event.value!;
  
                this.chartLabels.push(event.label);
              });
  
              chartData.label = this.selectedSensor + ' (' + total.toString() + ')';
  
              this.chartData.push(chartData);
  
              this.loading = false;
            },
            error: err => {
              this.chartData = undefined;
              this.loading = false;
              this.openSnackBar("No Data On This Date Range", "Close");
            }
          });
            break;   
          case "Demographics":
            if(this.selectedResearcher.name == 'mindLAMP-JHU')
              this.isJHU = true;
            else
              this.isJHU = false;

              this.service.getDemographicsByParticipant(this.selectedParticipant?.name!, this.isJHU).subscribe({
                next: event => {
                  this.demographicsInfo=event;
                  this.loading = false;
                },
                error: err => {
                  this.demographicsInfo=undefined;
                  this.loading = false;
                  this.openSnackBar("No Data On This Date Range", "Close");
                }
              });
            break;
        default:
          this.chartData = undefined;
          this.loading = false;
          this.openSnackBar("Selected sensor implementation not found", "Close");
          break;
    }   
  }

  chartClicked(value) {

    if (value.active.length > 0) {

      let dialogRef = this.dialog.open(ParticipantEventsDetailComponent, {
        data: {
          participant: this.selectedParticipant,
          event: this.selectedSensor,
          start: this.startDate,
          end: this.endDate,
          index: value.active[0]._index
        }, disableClose: true, width: '105%', height: '95%', maxHeight: '95%'
      });

      dialogRef.afterClosed().subscribe();
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
