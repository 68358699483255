
import { formatDate } from '@angular/common';
import {AfterViewInit, Component, ViewChild, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Participant } from '../models/participant';
import { Researcher } from '../models/researcher';

import { Study } from '../models/study';
import { LampService } from '../services/lamp.service';
import { OutcomeMeasurement } from '../models/outcomemeasurement';


@Component({
  selector: 'app-outcome-measurement',
  templateUrl: './outcome-measurement.component.html',
  styleUrls: ['./outcome-measurement.component.scss']
})

export class OutcomeMeasurementComponent implements OnInit, AfterViewInit {


  researchers: Researcher[] | undefined;
  selectedResearcher: Researcher | undefined;
  studies: Study[] | undefined;
  selectedStudy: Study | undefined;
  participants: Participant[] | undefined;
  selectedParticipant: Participant | undefined;

  loading: boolean = false;
  isJHU : boolean | false;
  
  measurementDisplayedColumns = ['outcometype','measurementtype','source','frequency','completionrate','totalcount','completionpercentage'];
  measurementDataSource: MatTableDataSource<OutcomeMeasurement> = new MatTableDataSource;
  measurementList: OutcomeMeasurement[] | undefined;
  measurementShowTable: boolean = false;

  normalize: boolean = false;
  date: Date = new Date();

  @ViewChild('measurementSort') measurementSort: MatSort;

  
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.measurementDataSource.sort) {
      this.measurementDataSource.sort = sort;
    }
  }

  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar) { 
  }

  ngOnInit(): void {

    this.service.getResearchers().subscribe(researchers => {
      this.researchers = researchers; 
    });
  }

  ngAfterViewInit() {
    this.measurementDataSource.sort = this.measurementSort!;
  }

  researcherChanged() {

    this.service.getStudiesByResearcher(this.selectedResearcher?.researcherId!).subscribe(studies => {
      this.studies = studies;
    });

    this.selectedStudy = undefined;
    this.participants = undefined;
    this.selectedParticipant = undefined;
    this.resetData();
  }

  studyChanged() {
    this.participants = undefined;
    this.selectedParticipant = undefined;    
    this.service.getParticipantsByStudy(this.selectedStudy?.studyId!).subscribe(participants => {
      this.participants = participants;
    });
    this.resetData();
  }
  
  participantChanged() {
    this.resetData();
  }
  resetData() {
    this.measurementDataSource.data = [];
    this.measurementShowTable = false;
  }

  populateData() {
    this.generateTable();
  }

  generateTable() {
    var lastVal: number = -1;

    this.measurementDataSource.data = [];
    this.loading = true;

    this.measurementShowTable = false;

    if(this.selectedResearcher.name == 'mindLAMP-JHU')
      this.isJHU = true;
    else
      this.isJHU = false;
    
    this.service.getOutcomeMeasurementForByParticipant(this.selectedStudy?.studyId!, this.selectedParticipant?.participantId!, this.selectedParticipant?.name!, this.isJHU).subscribe({
      next: events => {
        this.measurementList = events;
        this.measurementDataSource.data = this.measurementList;
        this.measurementDataSource.sort = this.measurementSort!;
        this.loading = false;
        this.measurementShowTable = true;
      },
      error: err => {
        this.loading = false;
        this.measurementShowTable = false;
        this.openSnackBar("Error Loading Measurement Data", "Close");
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
