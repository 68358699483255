import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material.module';
import { SideBarComponent } from './main/side-bar/side-bar.component';
import { HeaderComponent } from './main/header/header.component';
import { FooterComponent } from './main/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ParticipantEventsComponent } from './participant-events/participant-events.component';
import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { MostRecentEventsComponent } from './most-recent-events/most-recent-events.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ParticipantActivitiesComponent } from './participant-activities/participant-activities.component';
import { StudyEventsComponent } from './study-events/study-events.component';
import { AppConfigService } from './services/app-config.service';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { AgmCoreModule } from '@agm/core';
import {MapsConfig} from './services/mapconfig';
import { LAZY_MAPS_API_CONFIG} from '@agm/core';

/*
 * During the move from TFS to ADO, angular stoped recognizing the prefix of the app
 * This caused it to look for resources at dot*.mayo.edu/ instead of dot*.mayo.edu/rrtc/rod
 * Adding app base href fixes this issue.
 */
import { FailedComponent } from './failed/failed.component';
import { ParticipantEventsDetailComponent } from './participant-events/participant-events-detail/participant-events-detail.component';
import { StudyEventsDetailComponent } from './study-events/study-events-detail/study-events-detail.component';
import { ParticipantActivitiesDetailComponent } from './participant-activities/participant-activities-detail/participant-activities-detail.component';
import { StudyActivitiesComponent } from './study-activities/study-activities.component';
import { StudyActivitiesDetailComponent } from './study-activities/study-activities-detail/study-activities-detail.component';
import { ParticipantSummaryComponent } from './participant-summary/participant-summary.component';
import { DataExtractJobComponent } from './data-extract-job/data-extract-job.component';
import { SleepLogComponent } from './sleep-log/sleep-log.component';
import { SleepSensorMeqLogComponent } from './sleep-sensor-meq-log/sleep-sensor-meq-log.component';
import { DemographicsSummaryComponent } from './demographics-summary/demographics-summary.component';
import { OutcomeMeasurementComponent } from './outcome-measurement/outcome-measurement.component';
import { StudyTeamVisitsComponent } from './study-team-visits/study-team-visits.component';

export function AppConfigFactory(configService: AppConfigService) {
  return () => configService.loadAppConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    SideBarComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ParticipantEventsComponent,
    MostRecentEventsComponent,
    ParticipantActivitiesComponent,
    StudyEventsComponent,
    FailedComponent,
    ParticipantEventsDetailComponent,
    StudyEventsDetailComponent,
    ParticipantActivitiesDetailComponent,
    StudyActivitiesComponent,
    StudyActivitiesDetailComponent,
    ParticipantSummaryComponent,
    DataExtractJobComponent,
    SleepLogComponent,
    SleepSensorMeqLogComponent,
    DemographicsSummaryComponent,
    OutcomeMeasurementComponent,
    StudyTeamVisitsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ChartsModule,
    MatTableExporterModule,
    FormsModule,
    MsalModule,
    AgmCoreModule.forRoot()
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppConfigFactory,
      deps: [AppConfigService],
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: LAZY_MAPS_API_CONFIG,
      useClass: MapsConfig
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  entryComponents: [
    ParticipantEventsDetailComponent,
    StudyEventsDetailComponent,
    ParticipantActivitiesDetailComponent
  ]
})
export class AppModule { }
