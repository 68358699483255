import { formatDate } from '@angular/common';
import {AfterViewInit, Component, ViewChild, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Study } from '../models/study';
import { LampService } from '../services/lamp.service';
import { Demographics } from '../models/demographics';

import { ChartDataSets } from 'chart.js';
import { ChartData } from '../models/chartData';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as pluginChartAnnotation from 'chartjs-plugin-annotation';
import { Label } from 'ng2-charts/lib/base-chart.directive';
import { Color } from 'ng2-charts/lib/color';

@Component({
  selector: 'app-demographics-summary',
  templateUrl: './demographics-summary.component.html',
  styleUrls: ['./demographics-summary.component.scss']
})

export class DemographicsSummaryComponent implements OnInit, AfterViewInit {

  loading: boolean = false;

  demographicsDisplayedColumns = ['participantDesc', 'gender', 'ethnicity', 'race', 'lvl_ed', 'dob', 'age', 'agegroup', 'diagnosis'];
  demographicsDataSource: MatTableDataSource<Demographics> = new MatTableDataSource;
  demographicsList: Demographics[] | undefined;
  demographicsShowTable: boolean = false;

  normalize: boolean = false;
  date: Date = new Date();
  
  @ViewChild('demographicsSort') demographicsSort: MatSort;
  studyTypes: string[] = ["Mayo", "JHU"];
  selectedGroup: string = "Mayo";

  selectedType: string = "pie";
  genderChartData: ChartDataSets[] | undefined;
  genderChartLabels!: Label[];
  controlChartData: ChartDataSets[] | undefined;
  controlChartLabels!: Label[];
  ethinicityChartData: ChartDataSets[] | undefined;
  ethinicityChartLabels!: Label[];
  diagnosisChartData: ChartDataSets[] | undefined;
  diagnosisChartLabels!: Label[];
  chartOptions = undefined;
  AllChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins:{         
      datalabels: {
        color: 'white',
        formatter: function(value, context) {                  
          return Math.round(value/context.chart.getDatasetMeta(0).total * 100) + "%" ;
        }
      }
    }
  };
  chartColors: Color[] = [];
  chartLegend = true;
  chartPlugins = [pluginDataLabels, pluginChartAnnotation,
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 20; // must use `function` and not => because of `this`
        };
      }
    }];

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.demographicsDataSource.sort) {
      this.demographicsDataSource.sort = sort;
    }
  }
  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar) { 
    this.chartColors = service.getChartColors();
  }

  ngOnInit(): void {
    this.populateData();
  }

  ngAfterViewInit() {
    this.demographicsDataSource.sort = this.demographicsSort!;
  }

  groupChanged() {
    this.populateData();
  }
  populateData() {
    this.generateTable();
    this.generateChart();
  }
  
  getAgeGroup(age :string) {
    if(!isNaN(Number(age)) && age !=''){
      var ageValue = Number(age);
      if (ageValue <= 12)
        return "Children"
      else if (ageValue <= 64)
        return "Adults"
      else return "Older Adults";
    } else{
        return "";
    }
  }
  generateChart() {
    this.genderChartData = undefined;
  }

  generateTable() {
    var lastVal: number = -1;

    this.demographicsDataSource.data = [];
    this.loading = true;

    this.demographicsShowTable = false;
  
    this.service.getDemographicsForAll(this.selectedGroup).subscribe({
      next: events => {
        this.demographicsList = events;
        this.demographicsList.forEach((client) => {
          client.agegroup=this.getAgeGroup(client.age);
        });

        this.demographicsDataSource.data = this.demographicsList;
        this.demographicsDataSource.sort = this.demographicsSort!;
        this.loading = false;
        this.demographicsShowTable = true;
        this.generateGraph();
      },
      error: err => {
        this.loading = false;
        this.demographicsShowTable = false;
        this.demographicsList=undefined;
        this.openSnackBar("Error Loading Sleep Summary Table Data", "Close");
      }
    });
  }

  generateGraph() {
    console.log('generateGraph is called');
    this.genderChartData = undefined;
    this.chartOptions = this.AllChartOptions;
    if (this.demographicsList != undefined && this.demographicsList != null) {

      this.genderChartData = new Array<ChartDataSets>();
      this.genderChartLabels = new Array<string>();
      var genderData = new ChartData();
      genderData.data = new Array<number>();
      var maleTotal = 0;
      var femaleTotal = 0;
      var unknownGenderTotal = 0;

      this.controlChartData = new Array<ChartDataSets>();
      this.controlChartLabels = new Array<string>();
      var controlTotal = 0;
      var uncontrolTotal = 0;
      var unknownControlTotal = 0;
      var controlData = new ChartData();
      controlData.data = new Array<number>();

      this.ethinicityChartData = new Array<ChartDataSets>();
      this.ethinicityChartLabels = new Array<string>();
      var hispanicTotal = 0;
      var notHispanicTotal = 0;
      var unknownEthinicityTotal = 0;
      var ethinicityData = new ChartData();
      ethinicityData.data = new Array<number>();

      
      this.diagnosisChartData = new Array<ChartDataSets>();
      this.diagnosisChartLabels = new Array<string>();
      var bipolar1Total = 0;
      var bipolar2Total = 0;
      var disorderTotal = 0;
      var unknownDiagnosisTotal = 0;
      var diagnosisData = new ChartData();
      diagnosisData.data = new Array<number>();

      this.demographicsList.forEach(event => {
        if (event.gender == "Male") {
          maleTotal++;
        }
        if (event.gender == "Female") {
          femaleTotal++;
        } else {
          unknownGenderTotal++;
        }

        if (event.participantDesc.endsWith("C")) {
          controlTotal++;
        } else if (event.participantDesc.endsWith("P")) {
          uncontrolTotal++;
        } else {
          unknownControlTotal++;
        }
        
        if (event.ethnicity== "Hispanic or Latino") {
          hispanicTotal++;
        } else if (event.ethnicity== "NOT Hispanic or Latino") {
          notHispanicTotal++;
        } else {
          unknownEthinicityTotal++;
        }
        if (event.diagnosis == "Bipolar I") {
          bipolar1Total++;
        } else if (event.diagnosis== "Bipolar II") {
          bipolar2Total++;
        } else if (event.diagnosis== "Major Depressive Disorder") {
          disorderTotal++;
        } else {
          unknownDiagnosisTotal++;
        }

      });

      genderData.data!.push(maleTotal);
      genderData.data!.push(femaleTotal);
      genderData.data!.push(unknownGenderTotal);
      this.genderChartLabels.push("Male");
      this.genderChartLabels.push("Female");
      this.genderChartLabels.push("Unknown");
      this.genderChartData.push(genderData);

      controlData.data!.push(controlTotal);
      controlData.data!.push(uncontrolTotal);
      controlData.data!.push(unknownControlTotal);
      this.controlChartLabels.push("Control");
      this.controlChartLabels.push("Uncontrol");
      this.controlChartLabels.push("Unknown");
      this.controlChartData.push(controlData);

      ethinicityData.data!.push(hispanicTotal);
      ethinicityData.data!.push(notHispanicTotal);
      ethinicityData.data!.push(unknownEthinicityTotal);
      this.ethinicityChartLabels.push("Hispanic or Latino");
      this.ethinicityChartLabels.push("NOT Hispanic or Latino");
      this.ethinicityChartLabels.push("Unknown");
      this.ethinicityChartData.push(ethinicityData);

      diagnosisData.data!.push(bipolar1Total);
      diagnosisData.data!.push(bipolar2Total);
      diagnosisData.data!.push(disorderTotal);
      diagnosisData.data!.push(unknownDiagnosisTotal);
      this.diagnosisChartLabels.push("BPI");
      this.diagnosisChartLabels.push("BPII");
      this.diagnosisChartLabels.push("MDD");
      this.diagnosisChartLabels.push("Unknown");
      this.diagnosisChartData.push(diagnosisData);
    
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
