import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IUserProfile } from '../../interfaces/userProfile';
import { AppConfigService } from '../../services/app-config.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  //paletteColor: string = 'primary';
  environment: string;
  isIframe = false;
  authenticated = false;
  profile!: IUserProfile;
  //photo: SafeResourceUrl | undefined;
  blobUrl: string;
  
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private http: HttpClient,
    public sanitizer: DomSanitizer,
    private msalBroadcastService: MsalBroadcastService
  ) {  }

  ngOnInit(): void {
    this.environment = AppConfigService.settings.env;

    //if (this.environment == 'dev') {
    //  this.paletteColor = 'accent';
    //}
    //else if (this.environment == 'int') {
    //  this.paletteColor = 'warn';
    //}

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {

        if (result?.payload?.account) {
          this.authService.instance.setActiveAccount(result.payload.account);

          this.authenticated = true;
          this.getProfile();
          this.getPhoto();
        }
      });

    this.authenticated = this.authService.instance.getAllAccounts().length > 0;

    if (this.authenticated) {
      this.getProfile();
      this.getPhoto();
    }
  }

  login() {

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  getProfile() {
    this.http.get<IUserProfile>(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
      });
  }

  getPhoto() {
    this.http.get(GRAPH_ENDPOINT + '/photo/$value', { responseType: 'blob' })
      .subscribe(photo => {

        var url = window.URL || window.webkitURL;
        this.blobUrl = url.createObjectURL(photo);
        //this.photo = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobUrl);
      });
  }

  logout() {
    this.authService.logout();
  }
}
