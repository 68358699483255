import { DatePipe, formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { catchError } from 'rxjs/operators';
import { Activity } from '../models/activity';
import { ActivityEvent } from '../models/activityEvent';
import { StudyActivityEvent } from '../models/activityEvent';
import { Participant } from '../models/participant';
import { Researcher } from '../models/researcher';
import { SensorEvent, StatsSensorEvent, MostRecentSensorEvent, DoubleSensorEvent, XYZSensorEvent,GPSSensorEvent } from '../models/sensorEvent';
import { Study } from '../models/study';
import { Job } from '../models/job';
import { AppConfigService } from './app-config.service';
import { Demographics } from '../models/demographics';
import { SleepSummary } from '../models/sleepsummary';
import { OutcomeMeasurement } from '../models/outcomemeasurement';
import { StudyTeamVisits } from '../models/studyTeamVisits';

@Injectable({
  providedIn: 'root'
})
export class LampService {  

  private colors = [
    {
      borderColor: '#0B84A5',
      backgroundColor: '#0B84A5', 
    },
    {
      borderColor: '#F6C85F',
      backgroundColor: '#F6C85F',
    },
    {
      borderColor: '#6F4E7C',
      backgroundColor: '#6F4E7C',
    },
    {
      borderColor: '#9DD866',
      backgroundColor: '#9DD866',
    },
    {
      borderColor: '#CA472F',
      backgroundColor: '#CA472F',
    },
    {
      borderColor: '#FFA056',
      backgroundColor: '#FFA056',
    },
    {
      borderColor: '#8DDDD0',
      backgroundColor: '#8DDDD0',
    }
  ];

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale: string) {}

  getChartColors() {

      return this.colors;
  }

  getTimeOffset() {
  var d = new Date();
  var n = d.getTimezoneOffset();
  return n;
  }

  convertTimestamptoTime(unixTimestamp: number) {
    // convert to milliseconds
    // and then create a new Date object
    var dateObj = new Date(unixTimestamp);
    return dateObj.toLocaleString();
  }

  formatStartDate(dateObject: Date) {
    var dateObj =  new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 0, 0, 0)
    var now_utc =  Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate(),
    dateObj.getUTCHours(), dateObj.getUTCMinutes(), dateObj.getUTCSeconds());
    return now_utc;
  }

  formatEndDate(dateObject: Date) {
    var dateObj =  new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 23, 59, 59)
    var now_utc =  Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate(),
    dateObj.getUTCHours(), dateObj.getUTCMinutes(), dateObj.getUTCSeconds());
    return now_utc;
  }

  getResearchers(): Observable<Researcher[]> {

    return this.http.get<Researcher[]>(this.getApiUrl() + 'Researcher')
        .pipe(catchError(this.handleError));
  }

  getStudiesByResearcher(researcherId: string): Observable<Study[]> {

    return this.http.get<Study[]>(this.getApiUrl() + 'Study/' + researcherId)
      .pipe(catchError(this.handleError));
  }

  getParticipantsByStudy(studyId: string): Observable<Participant[]> {

    return this.http.get<Participant[]>(this.getApiUrl() + 'Participant/' + studyId)
      .pipe(catchError(this.handleError));
  }

  addSensorEventsByParticipant(participantId: string, sensor: string, startDate: Date, customValue: number): Observable<StatsSensorEvent[]> {
    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
    this.formatStartDate(startDate)+ '/'+customValue+ '/')
    .pipe(catchError(this.handleError));  
   
  }

  getStepSensorEventsByParticipant(participantId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));  
  }

  getHeartRateSensorEventsByParticipant(participantId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getSleepSensorEventsByParticipant(participantId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getTelephonySensorEventsByParticipant(participantId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {
    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getScreenStateSensorEventsByParticipant(participantId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {
    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getXYZSensorEventsByParticipant(participantId: string, sensor: string, startDate: Date, endDate: Date): Observable<XYZSensorEvent[]> {
    return this.http.get<XYZSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getGPSSensorEventsByParticipant(participantId: string, sensor: string, startDate: Date, endDate: Date): Observable<GPSSensorEvent[]> {
    return this.http.get<GPSSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }
  
  getDemographicsByParticipant(participantDesc: string, isJHU: boolean): Observable<Demographics> {
    return this.http.get<Demographics>(this.getApiUrl() + 'Demographics/' + participantDesc + '/' + isJHU)
      .pipe(catchError(this.handleError));
  }

  getDemographicsByStudy(studyID: string, isJHU: boolean): Observable<Demographics[]> {
    return this.http.get<Demographics[]>(this.getApiUrl() + 'Demographics/Study/' + studyID + '/' + isJHU)
      .pipe(catchError(this.handleError));
  }
  getDemographicsForAll(selectedGroup: string): Observable<Demographics[]> {
    return this.http.get<Demographics[]>(this.getApiUrl() + 'Demographics/GetAllDemographics/'+selectedGroup)
      .pipe(catchError(this.handleError));
  }


  getAnalyticsSensorEventsByParticipant(participantId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));  
  }

  getEventDetailsByParticipant(participantId: string, sensor: string, startDate: Date, endDate: Date, index: number): Observable<DoubleSensorEvent[]> {

    return this.http.get<DoubleSensorEvent[]>(this.getApiUrl() + 'ParticipantEvent/' + participantId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset()+ '/' + index)
      .pipe(catchError(this.handleError));
  }

  getStepSensorEventsByStudy(studyId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'StudyEvent/' + studyId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getHeartRateSensorEventsByStudy(studyId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'StudyEvent/' + studyId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }
  
  getSleepSensorEventsByStudy(studyId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'StudyEvent/' + studyId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getTelephonySensorEventsByStudy(studyId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'StudyEvent/' + studyId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getScreenStateSensorEventsByStudy(studyId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'StudyEvent/' + studyId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getAnalyticsSensorEventsByStudy(studyId: string, sensor: string, startDate: Date, endDate: Date): Observable<StatsSensorEvent[]> {

    return this.http.get<StatsSensorEvent[]>(this.getApiUrl() + 'StudyEvent/' + studyId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getGPSSensorEventsByStudy(studyId: string, sensor: string, startDate: Date, endDate: Date): Observable<GPSSensorEvent[]> {

    return this.http.get<GPSSensorEvent[]>(this.getApiUrl() + 'StudyEvent/' + studyId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getEventDetailsByStudy(studyId: string, sensor: string, startDate: Date, endDate: Date, index: number): Observable<DoubleSensorEvent[]> {

    return this.http.get<DoubleSensorEvent[]>(this.getApiUrl() + 'StudyEvent/' + studyId + '/' + sensor + '/' +
      this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset() + '/' + index)
      .pipe(catchError(this.handleError));
  }

  getMostRecentEventsByParticipant(participantId: string): Observable<MostRecentSensorEvent[]> {
    return this.http.get<MostRecentSensorEvent[]>(this.getApiUrl() + 'SensorEvent/MostRecent/' + participantId)
      .pipe(catchError(this.handleError));
  }

  getActivitiesByStudy(studyId: string, spec: string): Observable<Activity[]> {
    return this.http.get<Activity[]>(this.getApiUrl() + 'Activity/' + studyId + '/' + spec)
      .pipe(catchError(this.handleError));
  }

  getActivityEventsByParticipant(participantId: string, activityId: string, startDate: Date, endDate: Date): Observable<ActivityEvent[]> {
    return this.http.get<ActivityEvent[]>(this.getApiUrl() + 'ParticipantActivity/' + participantId + '/' + activityId + '/' +
       this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getActivityDetailsByParticipant(participantId: string, activityId: string, startDate: Date, endDate: Date, index: number): Observable<ActivityEvent[]> {
    return this.http.get<ActivityEvent[]>(this.getApiUrl() + 'ParticipantActivity/' + participantId + '/' + activityId + '/' +
       this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset() + '/' + index)
      .pipe(catchError(this.handleError));
  }

  getActivityByStudy(studyId: string, activityId: string, startDate: Date, endDate: Date): Observable<StudyActivityEvent[]> {
    return this.http.get<StudyActivityEvent[]>(this.getApiUrl() + 'StudyActivity/' + studyId + '/' + activityId + '/' +
       this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset())
      .pipe(catchError(this.handleError));
  }

  getActivityDetailsByStudy(studyId: string, activityId: string, startDate: Date, endDate: Date, index: number): Observable<StudyActivityEvent[]> {
    return this.http.get<StudyActivityEvent[]>(this.getApiUrl() + 'StudyActivity/' + studyId + '/' + activityId + '/' +
       this.formatStartDate(startDate) + '/' + this.formatEndDate(endDate)+ '/'+this.getTimeOffset() + '/' + index)
      .pipe(catchError(this.handleError));
  }

  getAllJobs(): Observable<Job[]> {
    return this.http.get<Job[]>(this.getApiUrl() + 'DataExtractJob/AllJobs')
      .pipe(catchError(this.handleError));
  }

  createJobByParticipant(job: Job): Observable<Job> {
    return this.http.post<Job>(this.getApiUrl() +  'DataExtractJob/CreateJob/', job)
      .pipe(catchError(this.handleError));
  }
  
  getFirstSensorEventByParticipant(participantId: string, sensor: string): Observable<DoubleSensorEvent> {
    return this.http.get<DoubleSensorEvent>(this.getApiUrl() + 'SensorEvent/FirstSensorEvent/' + participantId+'/'+ sensor)
      .pipe(catchError(this.handleError));
  }

  getLastSensorEventByParticipant(participantId: string, sensor: string): Observable<DoubleSensorEvent> {
    return this.http.get<DoubleSensorEvent>(this.getApiUrl() + 'SensorEvent/LastSensorEvent/' + participantId+'/'+ sensor)
      .pipe(catchError(this.handleError));
  }
  
  getSleepSummaryForByParticipant(participantId: string, participantDesc: string, isJHU: boolean): Observable<SleepSummary[]> {
    return this.http.get<SleepSummary[]>(this.getApiUrl() + 'SleepLog/SleepSummary/' + participantId+  '/' +participantDesc + '/' + isJHU)
      .pipe(catchError(this.handleError));
  }
  getOutcomeMeasurementForByParticipant(studyId: string, participantId: string, participantDesc: string, isJHU: boolean): Observable<OutcomeMeasurement[]> {
    return this.http.get<OutcomeMeasurement[]>(this.getApiUrl() + 'SleepLog/OutcomeMeasurement/'+ studyId +  '/' + participantId+  '/' +participantDesc + '/' + isJHU)
      .pipe(catchError(this.handleError));
  }
  getStudyTeamVisitsByParticipant(studyId: string, participantId: string, participantDesc: string, isJHU: boolean): Observable<StudyTeamVisits[]> {
    return this.http.get<StudyTeamVisits[]>(this.getApiUrl() + 'SleepLog/StudyTeamVisits/'+ studyId +  '/' + participantId+  '/' +participantDesc + '/' + isJHU)
      .pipe(catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

   
  getApiUrl(): string {
    var url="https://localhost:44388/api/";
    if (AppConfigService.settings.apiBaseUrl.indexOf("localhost") != -1) { 
      url = 'https://localhost:44388/api/';
    } else if (AppConfigService.settings.apiBaseUrl.indexOf("mindlamp-dev-api") != -1) { 
      url = 'https://mindlamp-dev-api.mayo.edu/api/';
    } else if (AppConfigService.settings.apiBaseUrl.indexOf("mindlamp-prod-api") != -1) { 
      url = 'https://mindlamp-prod-api.mayo.edu/api/';
    }
    return url;
  }
}
