import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authService: MsalService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    try {
      let activeAccount = this.authService.instance.getActiveAccount();

      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {

        let accounts = this.authService.instance.getAllAccounts();
        this.authService.instance.setActiveAccount(accounts[0]);

        activeAccount = this.authService.instance.getActiveAccount();
      }

      const userRoles = (activeAccount?.idTokenClaims as any).roles;
      const allowedRoles = next.data["roles"];
      const matchingRoles = userRoles.filter((x: any) => allowedRoles.includes(x));
      return matchingRoles.length > 0;
    } catch (e) {
      return false;
    }

  }
}
