import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Participant } from '../../models/participant';
import { DoubleSensorEvent } from '../../models/sensorEvent';
import { LampService } from '../../services/lamp.service';

@Component({
  selector: 'app-participant-events-detail',
  templateUrl: './participant-events-detail.component.html',
  styleUrls: ['./participant-events-detail.component.scss']
})
export class ParticipantEventsDetailComponent implements OnInit {
  participant: Participant;
  event: string;
  startDate: Date = new Date();
  endDate: Date = new Date();
  index: number;
  loading: boolean = false;
  showTable: boolean = false;
  normalize: boolean = false;
  date: Date = new Date();
  displayedColumns = ['sensor', 'timestamp', 'value'];
  dataSource: MatTableDataSource<DoubleSensorEvent> = new MatTableDataSource;
  sensorEvents: DoubleSensorEvent[] | undefined;

  @ViewChild(MatSort) sort: MatSort | undefined;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
      this.dataSource.sort = sort;
    }
  }

  constructor(private service: LampService,
    @Inject(MAT_DIALOG_DATA) data, private snackBar: MatSnackBar) {

    this.participant = data.participant;
    this.event = data.event;
    this.startDate = data.start;
    this.endDate = data.end;
    this.index = data.index;
    if (this.event == 'Telephony' || this.event == 'Screen_State'|| this.event == 'Analytics')
      this.displayedColumns = ['sensor', 'timestamp', 'value', 'description'];
    this.generateTable();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort!;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'eventDate': return new Date(item.eventDate);
        default: return item[property];
      }
    };
  }

  generateTable() {
    var lastVal: number = -1;
    this.dataSource.data = [];
    this.loading = true;
    this.showTable = false;
    this.service.getEventDetailsByParticipant(this.participant.participantId!, this.event.toLowerCase(), this.startDate, this.endDate, this.index).subscribe({
      next: events => {
        this.sensorEvents = events;
        this.dataSource.data = this.sensorEvents;
        this.loading = false;
        this.showTable = true;
      },
      error: err => {
        this.loading = false;
        this.showTable = false;
        this.openSnackBar("Error Loading Table Data", "Close");
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
