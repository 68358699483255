import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RoleGuard } from './guards/role.guard';
import { LampService } from './services/lamp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'LAMP Data Browser';
  authenticated = false;
  loggingIn = true;
  private readonly _destroying$ = new Subject<void>();

  constructor(iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private service: LampService ) {

    iconRegistry.addSvgIcon('light-bulb-svg', sanitizer.bypassSecurityTrustResourceUrl('assets/images/lightbulb.svg'));
  }

  ngOnInit(): void {

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {

        if (this.checkAndSetActiveAccount()) {
          this.setAuthenticated();
        }

        this.loggingIn = false
      })
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (!this.authenticated && this.loggingIn) {
        this.login();
      }
    }, 2000);
  }

  login() {

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  setAuthenticated() {
    this.authenticated = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(): boolean {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {

      activeAccount = this.authService.instance.getAllAccounts()[0];
      this.authService.instance.setActiveAccount(activeAccount);
    }

    return (activeAccount?.idTokenClaims as any).roles.filter((x: any) => ['Administrator', 'Researcher', 'Coordinator', 'Participant'].includes(x)).length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
