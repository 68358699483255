import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color, Label } from 'ng2-charts';
import { Activity } from '../models/activity';
import { ChartData } from '../models/chartData';
import { Participant } from '../models/participant';
import { Researcher } from '../models/researcher';
import { Study } from '../models/study';
import { ParticipantEventsDetailComponent } from '../participant-events/participant-events-detail/participant-events-detail.component';
import { LampService } from '../services/lamp.service';
import { ParticipantActivitiesDetailComponent } from './participant-activities-detail/participant-activities-detail.component';

@Component({
  selector: 'app-participant-activities',
  templateUrl: './participant-activities.component.html',
  styleUrls: ['./participant-activities.component.scss']
})
export class ParticipantActivitiesComponent implements OnInit {
  researchers: Researcher[] | undefined;
  selectedResearcher: Researcher | undefined;
  studies: Study[] | undefined;
  selectedStudy: Study | undefined;
  participants: Participant[] | undefined;
  selectedParticipant: Participant | undefined;
  activities: Activity[] | undefined;
  selectedActivities: Activity[] | undefined = new Array<Activity>();


  chartTypes: string[] = ["Bar", "Line"];
  selectedType: string = "Bar";
  chartData: ChartDataSets[] | undefined;

  startDate: Date = new Date();
  endDate: Date = new Date();

  loading: boolean = false;

  chartLabels!: Label[];
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          //labelString: 'Score'
        },
        ticks: {
          min: 0,
          beginAtZero: true,
          suggestedMax: 28
        }
      }]
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        clamp: true,
        color: 'black',
        font: {
          size: 12,
          weight: 'bold'
        },
        lineTension: 0,
        display: function(context) {
          return context.dataset.data[context.dataIndex] != null; // or >= 1 or ...
       }
      }
    }
  };
  chartColors: Color[] = [];
  chartLegend = true;
  chartPlugins = [pluginDataLabels,
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 20; // must use `function` and not => because of `this`
        };
      }
    }];

  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar, private dialog: MatDialog) {
    this.chartColors = service.getChartColors();
  }

  ngOnInit(): void {

    this.chartColors = this.service.getChartColors();

    this.service.getResearchers().subscribe(researchers => {
      this.researchers = researchers;
    });
  }

  researcherChanged() {

    this.service.getStudiesByResearcher(this.selectedResearcher?.researcherId!).subscribe(studies => {
      this.studies = studies;
    });

    this.selectedStudy = undefined;
    this.participants = undefined;
    this.selectedParticipant = undefined;
    this.chartData = undefined;
  }

  studyChanged() {

    this.participants = undefined;

    this.service.getParticipantsByStudy(this.selectedStudy?.studyId!).subscribe(participants => {
      this.participants = participants;
    });

    this.service.getActivitiesByStudy(this.selectedStudy.studyId, 'survey').subscribe(activities => {
      this.activities = activities;
    });

    this.selectedParticipant = undefined;
    this.chartData = undefined;
  }

  participantChanged() {
    this.resetChart();
    this.service.getFirstSensorEventByParticipant(this.selectedParticipant?.participantId!,"none").subscribe({
      next: events => {
        this.startDate= new Date(events.timestamp);
      },
      error: err => {
        this.startDate = new Date();
      }
    });
    this.service.getLastSensorEventByParticipant(this.selectedParticipant?.participantId!,"none").subscribe({
      next: events => {
        this.endDate= new Date(events.timestamp);
      },
      error: err => {
        this.endDate = new Date();
      }
    });
  }

  resetChart() {
    this.chartData = undefined;
  }

  verifyDates() {

    try {
      if (!this.startDate) {
        this.startDate = new Date();
      }

      if (!(this.endDate >= this.startDate)) {
        this.endDate = this.startDate
      }
    } catch (e) {

    }

    this.resetChart();
  }

  generateGraph_withoverlay() {   //   Adding in steps event to see what happens
    this.loading = true;
    this.chartData = undefined;
    this.chartLabels = new Array<string>();

    var charts = new Array<any>();
    var count = this.selectedActivities?.length! + 1;
       
 
    this.selectedActivities.forEach(activity => {

      this.service.getActivityEventsByParticipant(this.selectedParticipant?.participantId!, activity.activityId, this.startDate, this.endDate).subscribe({
        next: events => {

          var chartData = new ChartData();

          chartData.label = activity.name;
          chartData.data = new Array<number>();

          events.forEach(event => {

            if (event.score == null) {
              chartData.data!.push(null);
            } else {
              chartData.data!.push(Math.round((event.score + Number.EPSILON) * 100) / 100);  
            }

            if (this.chartLabels.length != events.length) {
              this.chartLabels.push(event.label);
            }
          });

          (chartData as any).participant = this.selectedParticipant.participantId;
          (chartData as any).action = 'activity';
          (chartData as any).actionType = activity.activityType;
          (chartData as any).id = activity.activityId;

          charts.push(chartData);

          count = count - 1;

          if (count == 0) {
            if (charts.length > 0) {
              this.chartData = new Array<ChartDataSets>();
              charts.forEach(chart => this.chartData!.push(chart));
            }
            else {
              this.openSnackBar("No Data On This Date Range", "Close");
            }

            this.loading = false;
          }
        },
        error: err => {
          count = count - 1;

          if (count == 0) {

            if (charts.length > 0) {
              this.chartData = new Array<ChartDataSets>();
              charts.forEach(chart => this.chartData!.push(chart));
            }
            else {
              this.openSnackBar("No Data On This Date Range", "Close");
            }

            this.loading = false;
          }
        }
      });
    });

    this.service.getStepSensorEventsByParticipant(this.selectedParticipant?.participantId, "steps", this.startDate, this.endDate).subscribe({
      next: events => {

        var chartData = new Array<number>();

        events.forEach(event => {
          if (event.value == null) {
            chartData.push(null);
          } else {
            chartData.push(Math.round(((event.value! / 1000) + Number.EPSILON) * 100) / 100);
          }

          if (this.chartLabels.length != events.length) {
            this.chartLabels.push(event.label);
          }
        });

        charts.push({
          data: chartData,
          label: 'Steps (Thousands)',
          type: 'line',
          fill: false,
          participant: this.selectedParticipant.participantId,
          action: "sensor",
          actionType: "steps"
        });

        count = count - 1;

        if (count == 0) {
          if (charts.length > 0) {
            this.chartData = new Array<ChartDataSets>();
            charts.forEach(chart => this.chartData!.push(chart));
          }
          else {
            this.openSnackBar("No Data On This Date Range", "Close");
          }

          this.loading = false;
        }
      },
      error: err => {
        count = count - 1;

        if (count == 0) {

          if (charts.length > 0) {
            this.chartData = new Array<ChartDataSets>();
            charts.forEach(chart => this.chartData!.push(chart));
          }
          else {
            this.openSnackBar("No Data On This Date Range", "Close");
          }

          this.loading = false;
        }
      }
    });
  }


  generateGraph() {
    this.loading = true;
    this.chartData = undefined;
    this.chartLabels = new Array<string>();

    var charts = new Array<any>();
    var count = this.selectedActivities?.length!;

    this.selectedActivities.forEach(activity => {

      this.service.getActivityEventsByParticipant(this.selectedParticipant?.participantId!, activity.activityId, this.startDate, this.endDate).subscribe({
        next: events => {

          var chartData = new ChartData();

          chartData.label = activity.name;
          chartData.data = new Array<number>();

          events.forEach(event => {

            if (event.score == null) {
              chartData.data!.push(null);
            } else {
              chartData.data!.push((Math.round(((event.score) + Number.EPSILON) * 100) / 100));
            }

            if (this.chartLabels.length != events.length) {
              this.chartLabels.push(event.label);
            }
          });

          (chartData as any).participant = this.selectedParticipant.participantId;
          (chartData as any).action = 'activity';
          (chartData as any).actionType = activity.activityType;
          (chartData as any).id = activity.activityId;

          charts.push(chartData);

          count = count - 1;

          if (count == 0) {
            if (charts.length > 0) {
              this.chartData = new Array<ChartDataSets>();
              charts.forEach(chart => this.chartData!.push(chart));
            }
            else {
              this.openSnackBar("No Data On This Date Range", "Close");
            }

            this.loading = false;
          }
        },
        error: err => {
          count = count - 1;

          if (count == 0) {

            if (charts.length > 0) {
              this.chartData = new Array<ChartDataSets>();
              charts.forEach(chart => this.chartData!.push(chart));
            }
            else {
              this.openSnackBar("No Data On This Date Range", "Close");
            }

            this.loading = false;
          }
        }
      });
    });
  }

  chartClicked(value) {

    if (value.active.length > 0) {
      var chart: any = this.chartData[value.active[0]._chart.getElementAtEvent(value.event)[0]._datasetIndex];

      //console.log(chart);

      if (chart.action == 'sensor') {
        let dialogRef = this.dialog.open(ParticipantEventsDetailComponent, {
          data: {
            participant: this.selectedParticipant,
            event: chart.actionType,
            start: this.startDate,
            end: this.endDate,
            index: value.active[0]._index
          }, disableClose: true, width: '105%', height: '95%', maxHeight: '95%'
        });

        dialogRef.afterClosed().subscribe();
      }
      else {
        let dialogRef = this.dialog.open(ParticipantActivitiesDetailComponent, {
          data: {
            participant: this.selectedParticipant,
            activity: chart.id,
            start: this.startDate,
            end: this.endDate,
            index: value.active[0]._index
          }, disableClose: true, width: '105%', height: '95%', maxHeight: '95%'
        });

        dialogRef.afterClosed().subscribe();
      }

      

      //  FIX HERE MAYBE:  https://medium.com/beereal/making-ng2-charts-work-for-us-553f4229665

    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
