import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartDataSets } from 'chart.js';
import { ChartOptions } from 'chart.js';
import { ChartPluginsOptions } from 'chart.js';
import * as pluginChartAnnotation from 'chartjs-plugin-annotation';
import { Label } from 'ng2-charts/lib/base-chart.directive';
import { Color } from 'ng2-charts/lib/color';
import { ChartData } from '../models/chartData';
import { Participant } from '../models/participant';
import { Researcher } from '../models/researcher';
import { Activity } from '../models/activity';
import { Study } from '../models/study';
import { LampService } from '../services/lamp.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { MatDialog } from '@angular/material/dialog';
import html2PDF from 'jspdf-html2canvas';
import { Demographics } from '../models/demographics';

@Component({
  selector: 'app-participant-summary',
  templateUrl: './participant-summary.component.html',
  styleUrls: ['./participant-summary.component.scss']
})

export class ParticipantSummaryComponent implements OnInit {

  today: number = Date.now();

  researchers: Researcher[] | undefined;
  selectedResearcher: Researcher | undefined;
  studies: Study[] | undefined;
  selectedStudy: Study | undefined;
  participants: Participant[] | undefined;
  selectedParticipant: Participant | undefined;
  chartTypes: string[] = ["Bar", "Line"];
  selectedType: string = "Bar";
  
  startDate: Date = new Date();
  endDate: Date = new Date();

  stepChartData: ChartDataSets[] | undefined;
  stepChartLabels!: Label[];
  stepLoading: boolean = false;

  heartRateChartData: ChartDataSets[] | undefined;
  heartRateChartLabels!: Label[];
  heartRateLoading: boolean = false;

  sleepChartData: ChartDataSets[] | undefined;
  sleepChartLabels!: Label[];
  sleepLoading: boolean = false;
  
  telephonyChartData: ChartDataSets[] | undefined;
  telephonyChartLabels!: Label[];
  telephonyLoading: boolean = false;

  screenStateChartData: ChartDataSets[] | undefined;
  screenStateChartLabels!: Label[];
  screenStateLoading: boolean = false;

  accelerometerChartData: ChartDataSets[] | undefined;
  accelerometerChartLabels!: Label[];
  accelerometerLoading: boolean = false;

  analyticsChartData: ChartDataSets[] | undefined;
  analyticsChartLabels!: Label[];
  analyticsLoading: boolean = false;
  
  activitiesChartData: ChartDataSets[] | undefined;
  activitiesChartLabels!: Label[];
  activitiesLoading: boolean = false;

  enableExport : boolean = false;
  exportingData:boolean = false;

  totalSteps: number | undefined;
  totalSleep: number | undefined;
  totalTelephone: number | undefined;
  totalScreenTime: number | undefined;
  totalAnalytics: number | undefined;
  demographicsInfo: Demographics | undefined;
  demographicsLoading: boolean = false;
  isJHU: boolean | undefined;
  calculatedMeanValue = 0;
  screenStateChartOptions = undefined;

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
         display: true,
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          //labelString: 'Score'
        },
        ticks: {
          min: 0,
          beginAtZero: true
        }
      }]
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        clamp: true,
        color: 'black',
        font: {
          size: 12,
          weight: 'bold'
        },
        display: function (context) {
          return context.dataset.data[context.dataIndex] != null; 
        }
      }
    }
  };
  ScreenStateOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
         display: true,
         maxBarThickness: 70,
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          //labelString: 'Score'
        },
        ticks: {
          min: 0,
          beginAtZero: true
        }
      }]
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    annotation: {
      // Defines when the annotations are drawn.
      // This allows positioning of the annotation relative to the other
      // elements of the graph.
      //
      // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
      // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
      drawTime: 'afterDatasetsDraw', // (default)

      // Mouse events to enable on each annotation.
      // Should be an array of one or more browser-supported mouse events
      // See https://developer.mozilla.org/en-US/docs/Web/Events
      events: ['click'],

      // Double-click speed in ms used to distinguish single-clicks from 
      // double-clicks whenever you need to capture both. When listening for
      // both click and dblclick, click events will be delayed by this
      // amount.
      dblClickSpeed: 350, // ms (default)

      // Array of annotation configuration objects
      // See below for detailed descriptions of the annotation options
      annotations: [{
          drawTime: 'afterDraw', // overrides annotation.drawTime if set
          id: 'a-line-1', // optional
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: this.calculatedMeanValue,
          borderColor: 'red',
          borderWidth: 2,
          borderDash: [5, 5], // Set the pattern for dashed line (5 pixels dashed, 5 pixels gap)
          label: {
            enabled: true,
            content: 'Mean: ' + this.calculatedMeanValue.toFixed(2), // Display the mean value as label
            position: 'left', // Adjust label position
            backgroundColor: 'black', // Label background color
            fontSize: 12, // Label font size
          },
          // Fires when the user clicks this annotation on the chart
          // (be sure to enable the event in the events array below).
          onClick: function(e) {
              // `this` is bound to the annotation element
          }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        clamp: true,
        color: 'black',
        font: {
          size: 12,
          weight: 'bold'
        },
        lineTension: 0,
        display: function (context) {
          return context.dataset.data[context.dataIndex] != null; 
        }
      }
    }
  };
  AcceleroMeterChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
         display: false,
      }],
    },
    plugins: {
      datalabels: {
          display: false,
      },
    } 
  };
  chartColors: Color[] = [];
  chartLegend = true;
  chartPlugins = [pluginDataLabels, pluginChartAnnotation,

    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 20; // must use `function` and not => because of `this`
        };
      }
    }];

  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar, private dialog: MatDialog) {
    this.chartColors = service.getChartColors();
  }

  ngOnInit(): void {

    this.service.getResearchers().subscribe(researchers => {
      this.researchers = researchers; 
    });
  }

  researcherChanged() {
    this.selectedStudy = undefined;
    this.studies= undefined;
    this.selectedParticipant = undefined;
    this.participants = undefined;
    this.demographicsInfo=undefined;
    this.resetChart();

    this.service.getStudiesByResearcher(this.selectedResearcher?.researcherId!).subscribe(studies => {
      this.studies = studies;
    });

  }

  studyChanged() {
    this.participants = undefined;
    this.selectedParticipant = undefined;
    this.resetChart();
    this.service.getParticipantsByStudy(this.selectedStudy?.studyId!).subscribe(participants => {
      this.participants = participants;
    });

  }

  participantChanged() {
    this.resetChart();
    this.service.getFirstSensorEventByParticipant(this.selectedParticipant?.participantId!,"none").subscribe({
      next: events => {
        this.startDate= new Date(events.timestamp);
      },
      error: err => {
        this.startDate = new Date();
      }
    });
    this.service.getLastSensorEventByParticipant(this.selectedParticipant?.participantId!,"none").subscribe({
      next: events => {
        this.endDate= new Date(events.timestamp);
      },
      error: err => {
        this.endDate = new Date();
      }
    });
  }

  resetChart() {
    this.stepChartData = undefined;
    this.heartRateChartData = undefined;
    this.sleepChartData = undefined;
    this.telephonyChartData = undefined;
    this.screenStateChartData = undefined;
    this.accelerometerChartData = undefined;
    this.analyticsChartData = undefined;
    this.activitiesChartData = undefined;
    this.demographicsInfo=undefined;
    this.enableExport = false;
    
    this.totalSteps =  undefined;
    this.totalSleep =  undefined;
    this.totalTelephone =  undefined;
    this.totalScreenTime =  undefined;
    this.totalAnalytics = undefined;
  }

  verifyDates() {
    try {
      if (!this.startDate) {
        this.startDate = new Date();
      }

      if (!(this.endDate >= this.startDate)) {
        this.endDate = this.startDate
      }
    } catch (e) {
    }

    this.resetChart();
  }
  updateExportStatus() {
    if (this.stepLoading == false && this.heartRateLoading == false && this.sleepLoading == false && this.telephonyLoading == false && 
      this.screenStateLoading == false && this.accelerometerLoading == false && this.analyticsLoading == false && this.activitiesLoading == false && this.demographicsLoading == false) {
        let canvasObjects = document.getElementsByTagName('canvas');
        const canvasArray = Array.from(canvasObjects);
        if (canvasArray.length > 0) {
          this.enableExport = true;        
        }
      }
  }

  generateGraph() {  
    this.resetChart()
    this.stepLoading = true;
    this.heartRateLoading = true;
    this.sleepLoading = true;
    this.telephonyLoading = true;
    this.screenStateLoading = true;
    this.accelerometerLoading = true;
    this.analyticsLoading = true;  
    this.activitiesLoading = true;
    this.demographicsLoading = true;

    this.service.getStepSensorEventsByParticipant(this.selectedParticipant?.participantId!, "steps", this.startDate, this.endDate).subscribe({
      next: events => {

        this.stepChartData = new Array<ChartDataSets>();
        this.stepChartLabels = new Array<string>();

        var chartData = new ChartData();
        var total = 0;

        chartData.data = new Array<number>();

        events.forEach(event => {
          chartData.data!.push(event.value!);

          total += event.value!;

          this.stepChartLabels.push(event.label);
        });

        chartData.label = "Steps" + ' (' + total.toString() + ')';

        this.totalSteps = total;

        this.stepChartData.push(chartData);

        this.stepLoading = false;
        this.updateExportStatus();
      },
      error: err => {
        this.stepChartData = undefined;
        this.stepLoading = false;
        this.updateExportStatus();
        //console.log('no steps data');
      }
    });

  this.service.getHeartRateSensorEventsByParticipant(this.selectedParticipant?.participantId!,  "heart_rate", this.startDate, this.endDate).subscribe({
    next: events => {
        this.heartRateChartData = new Array<ChartDataSets>();
        this.heartRateChartLabels = new Array<string>();
        
        var minData = new ChartData();
        var avgData = new ChartData();
        var maxData = new ChartData();

        minData.label = "Heart Rate" + ' (Min)';
        avgData.label = "Heart Rate"  + ' (Average)';
        maxData.label = "Heart Rate"  + ' (Peak)';

        minData.data = new Array<number>();
        avgData.data = new Array<number>();
        maxData.data = new Array<number>();

        events.forEach(event => {

          if (event.min == null) {
            minData.data!.push(null);
          } else {
            minData.data!.push(Math.round(event.min! + Number.EPSILON));
          }


          if (event.value == null) {
            avgData.data!.push(null);
          } else {
            avgData.data!.push(Math.round(event.value + Number.EPSILON));
          }

          if (event.max == null) {
            maxData.data!.push(null);
          } else {
            maxData.data!.push(Math.round(event.max! + Number.EPSILON));
          }

          this.heartRateChartLabels.push(event.label);
        });

        this.heartRateChartData.push(minData);
        this.heartRateChartData.push(avgData);
        this.heartRateChartData.push(maxData);

        this.heartRateLoading = false;
        this.updateExportStatus();
      },
      error: err => {
        this.heartRateChartData = undefined;
        this.heartRateLoading = false;
        this.updateExportStatus();
        //console.log('no HR data');
      }
    });

    this.service.getSleepSensorEventsByParticipant(this.selectedParticipant?.participantId!, "sleep", this.startDate, this.endDate).subscribe({
      next: events => {
        this.sleepChartData = new Array<ChartDataSets>();
        this.sleepChartLabels = new Array<string>();

        var chartData = new ChartData();
        var total = 0;

        chartData.data = new Array<number>();

        events.forEach(event => {


          if (event.value == null) {
            chartData.data!.push(null);
          } else {
            chartData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);  
            total += event.value!;
          }

          this.sleepChartLabels.push(event.label);
        });
        total = Math.round((total + Number.EPSILON) * 100) / 100;

        chartData.label = "Sleep" + ' (' + total.toString() + ') - Hours';
        this.totalSleep = total;
        this.sleepChartData.push(chartData);

        this.sleepLoading = false;
        this.updateExportStatus();
      },
      error: err => {
        this.sleepChartData = undefined;
        this.sleepLoading = false;
        this.updateExportStatus();
        //console.log('no sleep data');
      }
    });

    this.service.getTelephonySensorEventsByParticipant(this.selectedParticipant?.participantId!, "telephony", this.startDate, this.endDate).subscribe({
      next: events => {

        this.telephonyChartData = new Array<ChartDataSets>();
        this.telephonyChartLabels = new Array<string>();

        var chartData = new ChartData();
        var total = 0;

        chartData.data = new Array<number>();

        events.forEach(event => {

          if (event.value == null) {
            chartData.data!.push(null);
          } else {
            chartData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);  
            total += event.value!;
          }

          this.telephonyChartLabels.push(event.label);
        });
        total = Math.round((total + Number.EPSILON) * 100) / 100;
        chartData.label = "Telephony" + ' (' + total.toString() + ') - Minutes';
        this.totalTelephone = total;
        this.telephonyChartData.push(chartData);

        this.telephonyLoading = false;
        this.updateExportStatus();
      },
      error: err => {
        this.telephonyChartData = undefined;
        this.telephonyLoading = false;
        this.updateExportStatus();
        //console.log('no telephony data');
      }
    });

    this.service.getScreenStateSensorEventsByParticipant(this.selectedParticipant?.participantId!, "screen_state", this.startDate, this.endDate).subscribe({
      next: events => {

        this.screenStateChartData = new Array<ChartDataSets>();
        this.screenStateChartLabels = new Array<string>();

        var chartData = new ChartData();
        var total = 0;

        chartData.data = new Array<number>();

        var count = 0;
        events.forEach(event => {

          if (event.value == null) {
            chartData.data!.push(null);
          } else {
            chartData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);  
            total += event.value!;
          }

          count = count + 1;
          this.screenStateChartLabels.push(event.label);
        });
        total = Math.round((total + Number.EPSILON) * 100) / 100;
        this.calculatedMeanValue = total/count;
        this.ScreenStateOptions.annotation.annotations[0].value = this.calculatedMeanValue;
        this.ScreenStateOptions.annotation.annotations[0].label.content = this.calculatedMeanValue.toFixed(2).toString();
        this.screenStateChartOptions = this.ScreenStateOptions;
        
        chartData.label = "Screen State" + ' (' + total.toString() + ') - Minutes';
        this.totalScreenTime = total;
        this.screenStateChartData.push(chartData);

        this.screenStateLoading = false;
        this.updateExportStatus();
      },
      error: err => {
        this.screenStateChartData = undefined;
        this.screenStateLoading = false;
        this.updateExportStatus();
        //console.log('no screenState data');
      }
    });

    this.service.getXYZSensorEventsByParticipant(this.selectedParticipant?.participantId!, "accelerometer", this.startDate, this.endDate).subscribe({
      next: events => {

        this.accelerometerChartData = new Array<ChartDataSets>();
        this.accelerometerChartLabels = new Array<string>();

        var XData = new ChartData();
        var YData = new ChartData();
        var ZData = new ChartData();
        XData.label = "Accelerometer" + ' X';
        YData.label = "Accelerometer" + ' Y';
        ZData.label = "Accelerometer" + ' Z';

        XData.data = new Array<number>();
        YData.data = new Array<number>();
        ZData.data = new Array<number>();

        
        events.forEach(event => {
          if (event.x == null) {
            XData.data!.push(null);
          } else {
            XData.data!.push(Math.round((event.x! + Number.EPSILON) * 1000) / 1000);
          }

          if (event.y == null) {
            YData.data!.push(null);
          } else {
            YData.data!.push(Math.round((event.y! + Number.EPSILON) * 1000) / 1000);
          }

          if (event.z == null) {
            ZData.data!.push(null);
          } else {
            ZData.data!.push(Math.round((event.z! + Number.EPSILON) * 1000) / 1000);
          }

          this.accelerometerChartLabels.push(this.service.convertTimestamptoTime(event.timestamp));
        });

        var charts = new Array<any>();
        charts.push({
          data: XData.data,
          label: XData.label,
          type: 'line',
          fill: false,
          lineTension: 0.1,
          participant: this.selectedParticipant.participantId,
          action: "sensor",
          actionType: "accelerometer"
        });
        charts.push({
          data: YData.data,
          label: YData.label,
          lineTension: 0.1,
          type: 'line',
          fill: false,
          participant: this.selectedParticipant.participantId,
          action: "sensor",
          actionType: "accelerometer"
        });
        charts.push({
          data: ZData.data,
          label: ZData.label,
          type: 'line',
          fill: false,
          participant: this.selectedParticipant.participantId,
          action: "sensor",
          actionType: "accelerometer"
        });

        this.accelerometerChartData = new Array<ChartDataSets>();
        charts.forEach(chart => this.accelerometerChartData!.push(chart));

        this.accelerometerLoading = false;
        this.updateExportStatus();
      },
      error: err => {
        this.accelerometerChartData = undefined;
        this.accelerometerLoading = false;
        this.updateExportStatus();
        //console.log('no accelerometer data');
      }
    });
    
    this.service.getAnalyticsSensorEventsByParticipant(this.selectedParticipant?.participantId!, "analytics", this.startDate, this.endDate).subscribe({
      next: events => {

        this.analyticsChartData = new Array<ChartDataSets>();
        this.analyticsChartLabels = new Array<string>();

        var chartData = new ChartData();
        var total = 0;

        chartData.data = new Array<number>();

        events.forEach(event => {
          chartData.data!.push(event.value!);

          total += event.value!;

          this.analyticsChartLabels.push(event.label);
        });

        chartData.label = "Analytics" + ' (' + total.toString() + ') - Activities';
        this.totalAnalytics = total;
        this.analyticsChartData.push(chartData);

        this.analyticsLoading = false;
        this.updateExportStatus();
      },
      error: err => {
        this.analyticsChartData = undefined;
        this.analyticsLoading = false;
        this.updateExportStatus();
        //console.log('no analytics data');
      }
    });

    if(this.selectedResearcher.name == 'mindLAMP-JHU')
      this.isJHU = true;
    else
      this.isJHU = false;
    this.service.getDemographicsByParticipant(this.selectedParticipant?.name!, this.isJHU).subscribe({
      next: event => {
        this.demographicsInfo=event;
        this.demographicsLoading = false;
      },
      error: err => {
        this.demographicsInfo=undefined;
        this.demographicsLoading = false;
      }
    });


    var activities: Activity[] | undefined;
    this.service.getActivitiesByStudy(this.selectedStudy?.studyId!, 'survey').subscribe(activities => {
      activities = activities;
      var charts = new Array<any>();
      var count = activities?.length!;
      this.activitiesChartLabels = new Array<string>();
      //console.log(" activity count "+count);

      activities.forEach(activity => {
        //console.log(" activity.activityId "+activity.activityId);

        this.service.getActivityEventsByParticipant(this.selectedParticipant?.participantId!, activity.activityId, this.startDate, this.endDate).subscribe({
          next: events => {

            var chartData = new ChartData();

            chartData.label = activity.name;
            chartData.data = new Array<number>();

            events.forEach(event => {

              if (event.score == null) {
                chartData.data!.push(null);
              } else {
                chartData.data!.push((Math.round(((event.score) + Number.EPSILON) * 100) / 100));
              }

              if (this.activitiesChartLabels.length != events.length) {
                this.activitiesChartLabels.push(event.label);
              }
            });

            (chartData as any).participant = this.selectedParticipant.participantId;
            (chartData as any).action = 'activity';
            (chartData as any).actionType = activity.activityType;
            (chartData as any).id = activity.activityId;

            charts.push(chartData);

            count = count - 1;

            if (count == 0) {
              if (charts.length > 0) {
                this.activitiesChartData = new Array<ChartDataSets>();
                charts.forEach(chart => this.activitiesChartData!.push(chart));
              }
              this.activitiesLoading = false;
              this.updateExportStatus();
            }
          },
          error: err => {
            count = count - 1;

            if (count == 0) {

              if (charts.length > 0) {
                this.activitiesChartData = new Array<ChartDataSets>();
                charts.forEach(chart => this.activitiesChartData!.push(chart));
              }
              this.activitiesLoading = false;
              this.updateExportStatus();
            }
          }
        });
        
      });

    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  exportAsPDF() {
    let canvasObjects  = document.getElementsByName('lampchart');
    let canvasHTMLObjects :  NodeListOf<HTMLElement> = document.getElementsByName('lampchart');
    let participantInfo = document.getElementById('participantInfo');
    //participantInfo.style.visibility = "visible";

    if (canvasObjects.length > 0) {
      this.exportingData = true;
      
      let  pdf_top_left_margin = 15;  
      let Pdf_HTML_Width =canvasObjects[0].getBoundingClientRect().width;
      let Pdf_HTML_Height =canvasObjects[0].getBoundingClientRect().height;
      let PDF_Width = Pdf_HTML_Width + (pdf_top_left_margin * 2);
      let PDF_Height = Pdf_HTML_Height + (pdf_top_left_margin * 2);
      console.log("PDF_Width  = " + PDF_Width + ", PDF_Height  = " + PDF_Height);  
      const canvasArray = Array.from(canvasHTMLObjects);
      canvasArray.unshift(participantInfo);

      var currentObject = this;
      html2PDF(canvasArray, {
          jsPDF: {
              orientation: 'l',
              unit: 'pt',
              format: [PDF_Width, PDF_Height]
          },
          imageType: 'image/jpeg',
          output: currentObject.selectedParticipant.name+'-visit-report.pdf',
            init: function() { 
              //console.log('initializing');
            },

          success: function(pdf) {
            pdf.save(this.output);
            //console.log('saved');
            currentObject.exportingData = false;
            //participantInfo.style.visibility = "hidden";
          }
      });
    }
  }  

}
