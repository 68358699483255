import { formatDate } from '@angular/common';
import {AfterViewInit, Component, ViewChild, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Researcher } from '../models/researcher';
import { Study } from '../models/study';
import { Participant } from '../models/participant';
import { Job } from '../models/job';
import { LampService } from '../services/lamp.service';

@Component({
  selector: 'app-data-extract-job',
  templateUrl: './data-extract-job.component.html',
  styleUrls: ['./data-extract-job.component.scss']
})

export class DataExtractJobComponent implements OnInit, AfterViewInit {

  researchers: Researcher[] | undefined;
  selectedResearcher: Researcher | undefined;
  studies: Study[] | undefined;
  selectedStudy: Study | undefined;
  participants: Participant[] | undefined;
  selectedParticipant: Participant | undefined;
  newJob: Job = new Job();

  startDate: Date = new Date();
  endDate: Date = new Date();

  loading: boolean = false;

  @ViewChild(MatSort) sort: MatSort| undefined;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.dataSource.sort) {
        this.dataSource.sort = sort;
    }
  }
  
  displayedColumns = ['name','participantName','starttimestamp', 'endtimestamp','requester','timestamp', 'status', 'filelocation'];
  dataSource: MatTableDataSource<Job> = new MatTableDataSource;
  jobs: Job[] | undefined;

  showTable: boolean = false;
  normalize: boolean = false;
  date: Date = new Date();

  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.service.getResearchers().subscribe(researchers => {
      this.researchers = researchers; 
    });
    this.getJobs();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort!;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default: return item[property];
      }
    };
  }

  researcherChanged() {

    this.service.getStudiesByResearcher(this.selectedResearcher?.researcherId!).subscribe(studies => {
      this.studies = studies;
    });

    this.selectedStudy = undefined;
    this.participants = undefined;
    this.selectedParticipant = undefined;
  }

  studyChanged() {

    this.participants = undefined;
    this.service.getParticipantsByStudy(this.selectedStudy?.studyId!).subscribe(participants => {
      this.participants = participants;
    });

    this.selectedParticipant = undefined;
  }
  
  participantChanged() {
    this.service.getFirstSensorEventByParticipant(this.selectedParticipant?.participantId!,"none").subscribe({
      next: events => {
        this.startDate= new Date(events.timestamp);
      },
      error: err => {
        this.startDate = new Date();
      }
    });
    this.service.getLastSensorEventByParticipant(this.selectedParticipant?.participantId!,"none").subscribe({
      next: events => {
        this.endDate= new Date(events.timestamp);
      },
      error: err => {
        this.endDate = new Date();
      }
    });
  }

  verifyDates() {
    try {
      if (!this.startDate) {
        this.startDate = new Date();
      }

      if (!(this.endDate >= this.startDate)) {
        this.endDate = this.startDate
      }
    } catch (e) {
    }

  }

  createJob() {
    this.newJob.timestamp = (new Date()).getTime();
    this.newJob.name=this.selectedResearcher.name+ '-' +this.selectedParticipant.participantId + "-" +this.newJob.timestamp;
    this.newJob.researcherGroup=this.selectedResearcher.name;
    this.newJob.participantId = this.selectedParticipant.participantId;
    this.newJob.participantName = this.selectedParticipant.name;
    this.newJob.startTimestamp = this.formatStartDate(this.startDate);
    this.newJob.endTimestamp = this.formatEndDate(this.endDate);
    this.newJob.status="New";
    this.newJob.study=this.selectedStudy.name;
    this.newJob.fileLocation = "";
    this.service.createJobByParticipant(this.newJob).subscribe(res => {
      this.getJobs();
    });
  }

  formatStartDate(dateObject: Date) {
    var dateObj =  new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 0, 0, 0)
    var now_utc =  Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate(),
    dateObj.getUTCHours(), dateObj.getUTCMinutes(), dateObj.getUTCSeconds());
    return now_utc;
  }

  formatEndDate(dateObject: Date) {
    var dateObj =  new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 23, 59, 59)
    var now_utc =  Date.UTC(dateObj.getUTCFullYear(), dateObj.getUTCMonth(), dateObj.getUTCDate(),
    dateObj.getUTCHours(), dateObj.getUTCMinutes(), dateObj.getUTCSeconds());
    return now_utc;
  }

  getJobs() {
    var lastVal: number = -1;
    this.dataSource.data = [];
    this.loading = true;
    this.showTable = false;
    this.service.getAllJobs().subscribe({
      next: events => {
        this.jobs = events;
        this.dataSource.data = this.jobs;
        this.dataSource.sort = this.sort!;
        this.loading = false;
        this.showTable = true;
      },
      error: err => {
        this.loading = false;
        this.showTable = false;
        this.openSnackBar("Error Loading Table Data", "Close");
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
