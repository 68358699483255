import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ChartData } from '../models/chartData';
import { Participant } from '../models/participant';
import { Researcher } from '../models/researcher';
import { Study } from '../models/study';
import { GPSSensorEvent } from '../models/sensorEvent'
import { LampService } from '../services/lamp.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { StudyEventsDetailComponent } from './study-events-detail/study-events-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Demographics } from '../models/demographics';

@Component({
  selector: 'app-study-events',
  templateUrl: './study-events.component.html',
  styleUrls: ['./study-events.component.scss']
})
export class StudyEventsComponent implements OnInit {
  researchers: Researcher[] | undefined;
  selectedResearcher: Researcher | undefined;
  studies: Study[] | undefined;
  selectedStudy: Study | undefined;
  participants: Participant[] | undefined;
  sensors: string[] = ["Steps", "Heart_Rate", "Sleep", "Telephony","Screen_State","Analytics", "GPS", "Demographics"];
  selectedSensor: string = "Steps";
  chartTypes: string[] = ["Bar", "Line"];
  selectedType: string = "Bar";
  chartData: ChartDataSets[] | undefined;
  selectedSensorUnits: string = "";

  startDate: Date = new Date();
  endDate: Date = new Date();

  loading: boolean = false;

  chartLabels!: Label[];
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: true,
        maxBarThickness: 90,
     }],
      yAxes: [{
        scaleLabel: {
          display: true,
          //labelString: 'Score'
        },
        ticks: {
          min: 0,
          beginAtZero: true
        }
      }]
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        clamp: true,
        color: 'black',
        font: {
          size: 12,
          weight: 'bold'
        },
        lineTension: 0,
        display: function (context) {
          return context.dataset.data[context.dataIndex] != null; // or >= 1 or ...
        }
      }
    }
  };
  chartColors: Color[] = [];
  chartLegend = true;
  chartPlugins = [pluginDataLabels,
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 20; // must use `function` and not => because of `this`
        };
      }
    }];
  isJHU: boolean | undefined;
  demographicsList: Demographics[] | undefined;
  demographicsDisplayedColumns = ['participantDesc', 'dob', 'age', 'gender', 'lvl_ed', 'race', 'ethnicity'];
  //used for GPS
  geolocations: GPSSensorEvent[] | undefined;
  displayedColumns = ['latitude', 'longitude', 'timestamp', 'customColumn'];
  dataSource: MatTableDataSource<GPSSensorEvent> = new MatTableDataSource;


  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar, private dialog: MatDialog) {
    this.chartColors = service.getChartColors();
  }

  ngOnInit(): void {

    this.service.getResearchers().subscribe(researchers => {
      this.researchers = researchers;
    });
  }

  researcherChanged() {

    this.participants = undefined;
    this.studies = undefined;
    this.demographicsList = undefined;

    this.service.getStudiesByResearcher(this.selectedResearcher?.researcherId!).subscribe(studies => {
      this.studies = studies;
    });

    this.selectedStudy = undefined;
    this.resetChart();
  }

  studyChanged() {

    this.participants = undefined;
    this.service.getParticipantsByStudy(this.selectedStudy?.studyId!).subscribe(participants => {
      this.participants = participants;
    });

    this.resetChart();
  }

  sensorChanged() {
    switch (this.selectedSensor) {
      case "Sleep":
           this.selectedSensorUnits="Hours";
           break;
      case "Telephony":
           this.selectedSensorUnits="Minutes";
           break;
      case "Screen_State":
           this.selectedSensorUnits="Minutes";
           break;
      case "Accelerometer":
           this.selectedSensorUnits="";
           break;
      default:
           this.selectedSensorUnits="";
           break;
    }
    console.log('this.selectedSensor: '+this.selectedSensor+', this.selectedSensorUnits: '+this.selectedSensorUnits);
    this.resetChart();
  }

  resetChart() {
    this.chartData = undefined;
    this.geolocations = undefined;
    this.demographicsList = undefined;
  }


  verifyDates() {

    try {
      if (!this.startDate) {
        this.startDate = new Date();
      }

      if (!(this.endDate >= this.startDate)) {
        this.endDate = this.startDate
      }
    } catch (e) {

    }

    this.resetChart();
  }

  goToLink(latitude: number, longitude: number) {
    window.open("http://maps.google.com/?q="+latitude+","+longitude, "_blank");
  }

  generateGraph() {
    this.loading = true;
    this.chartData = undefined;
    this.geolocations = undefined;

    switch (this.selectedSensor) {
      case "Steps":

        this.service.getStepSensorEventsByStudy(this.selectedStudy?.studyId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
          next: events => {

            this.chartData = new Array<ChartDataSets>();
            this.chartLabels = new Array<string>();

            var minData = new ChartData();
            var avgData = new ChartData();
            var maxData = new ChartData();

            minData.label = this.selectedSensor + ' (Min)';
            avgData.label = this.selectedSensor + ' (Average)';
            maxData.label = this.selectedSensor + ' (Max)';

            minData.data = new Array<number>();
            avgData.data = new Array<number>();
            maxData.data = new Array<number>();

            events.forEach(event => {

              if (event.min == null) {
                minData.data!.push(null);
              } else {
                minData.data!.push(Math.round(event.min! + Number.EPSILON));
              }

              if (event.value == null) {
                avgData.data!.push(null);
              } else {
                avgData.data!.push(Math.round(event.value + Number.EPSILON));
              }

              if (event.max == null) {
                maxData.data!.push(null);
              } else {
                maxData.data!.push(Math.round(event.max! + Number.EPSILON));
              }

              this.chartLabels.push(event.label);
            });

            this.chartData.push(minData);
            this.chartData.push(avgData);
            this.chartData.push(maxData);

            this.loading = false;
          },
          error: err => {
            this.chartData = undefined;
            this.loading = false;
            this.openSnackBar("No Data On This Date Range", "Close");
          }
        });
        break;

      case "Heart_Rate":

        this.service.getHeartRateSensorEventsByStudy(this.selectedStudy?.studyId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
          next: events => {

            this.chartData = new Array<ChartDataSets>();
            this.chartLabels = new Array<string>();

            var minData = new ChartData();
            var avgData = new ChartData();
            var maxData = new ChartData();

            minData.label = this.selectedSensor + ' (Min)';
            avgData.label = this.selectedSensor + ' (Average)';
            maxData.label = this.selectedSensor + ' (Peak)';

            minData.data = new Array<number>();
            avgData.data = new Array<number>();
            maxData.data = new Array<number>();

            events.forEach(event => {

              if (event.min == null) {
                minData.data!.push(null);
              } else {
                minData.data!.push(Math.round(event.min! + Number.EPSILON));
              }

              if (event.value == null) {
                avgData.data!.push(null);
              } else {
                avgData.data!.push(Math.round(event.value + Number.EPSILON));
              }

              if (event.max == null) {
                maxData.data!.push(null);
              } else {
                maxData.data!.push(Math.round(event.max! + Number.EPSILON));
              }

              this.chartLabels.push(event.label);
            });

            this.chartData.push(minData);
            this.chartData.push(avgData);
            this.chartData.push(maxData);

            this.loading = false;
          },
          error: err => {
            this.chartData = undefined;
            this.loading = false;
            this.openSnackBar("No Data On This Date Range", "Close");
          }
        });
        break;
      case "Sleep":

        this.service.getSleepSensorEventsByStudy(this.selectedStudy?.studyId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
          next: events => {

            this.chartData = new Array<ChartDataSets>();
            this.chartLabels = new Array<string>();

            var minData = new ChartData();
            var avgData = new ChartData();
            var maxData = new ChartData();

            minData.label = this.selectedSensor + ' (Min)';
            avgData.label = this.selectedSensor + ' (Average)';
            maxData.label = this.selectedSensor + ' (Max)';

            minData.data = new Array<number>();
            avgData.data = new Array<number>();
            maxData.data = new Array<number>();

            events.forEach(event => {

              if (event.min == null) {
                minData.data!.push(null);
              } else {
                minData.data!.push(Math.round((event.min + Number.EPSILON) * 100) / 100);  
              }

              if (event.value == null) {
                avgData.data!.push(null);
              } else {
                avgData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);
              }

              if (event.max == null) {
                maxData.data!.push(null);
              } else {
                maxData.data!.push(Math.round((event.max + Number.EPSILON) * 100) / 100);
              }

              this.chartLabels.push(event.label);
            });

            this.chartData.push(minData);
            this.chartData.push(avgData);
            this.chartData.push(maxData);

            this.loading = false;
          },
          error: err => {
            this.chartData = undefined;
            this.loading = false;
            this.openSnackBar("No Data On This Date Range", "Close");
          }
        });
        break;
      case "Telephony":

        this.service.getTelephonySensorEventsByStudy(this.selectedStudy?.studyId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
          next: events => {

            this.chartData = new Array<ChartDataSets>();
            this.chartLabels = new Array<string>();

            var minData = new ChartData();
            var avgData = new ChartData();
            var maxData = new ChartData();

            minData.label = this.selectedSensor + ' (Min)';
            avgData.label = this.selectedSensor + ' (Average)';
            maxData.label = this.selectedSensor + ' (Max)';

            minData.data = new Array<number>();
            avgData.data = new Array<number>();
            maxData.data = new Array<number>();

            events.forEach(event => {

              if (event.min == null) {
                minData.data!.push(null);
              } else {
                minData.data!.push(Math.round((event.min! + Number.EPSILON) * 100) / 100);  
              }

              if (event.value == null) {
                avgData.data!.push(null);
              } else {
                avgData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);  
              }

              if (event.max == null) {
                maxData.data!.push(null);
              } else {
                maxData.data!.push(Math.round((event.max! + Number.EPSILON) * 100) / 100);  
              }

              this.chartLabels.push(event.label);
            });

            this.chartData.push(minData);
            this.chartData.push(avgData);
            this.chartData.push(maxData);

            this.loading = false;
          },
          error: err => {
            this.chartData = undefined;
            this.loading = false;
            this.openSnackBar("No Data On This Date Range", "Close");
          }
        });
        break;
      case "Screen_State":

        this.service.getScreenStateSensorEventsByStudy(this.selectedStudy?.studyId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
          next: events => {

            this.chartData = new Array<ChartDataSets>();
            this.chartLabels = new Array<string>();

            var minData = new ChartData();
            var avgData = new ChartData();
            var maxData = new ChartData();

            minData.label = this.selectedSensor + ' (Min)';
            avgData.label = this.selectedSensor + ' (Average)';
            maxData.label = this.selectedSensor + ' (Max)';

            minData.data = new Array<number>();
            avgData.data = new Array<number>();
            maxData.data = new Array<number>();

            events.forEach(event => {

              if (event.min == null) {
                minData.data!.push(null);
              } else {
                minData.data!.push(Math.round((event.min! + Number.EPSILON) * 100) / 100);  
              }

              if (event.value == null) {
                avgData.data!.push(null);
              } else {
                avgData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);  
              }

              if (event.max == null) {
                maxData.data!.push(null);
              } else {
                maxData.data!.push(Math.round((event.max! + Number.EPSILON) * 100) / 100);  
              }

              this.chartLabels.push(event.label);
            });

            this.chartData.push(minData);
            this.chartData.push(avgData);
            this.chartData.push(maxData);

            this.loading = false;
          },
          error: err => {
            this.chartData = undefined;
            this.loading = false;
            this.openSnackBar("No Data On This Date Range", "Close");
          }
        });
        break;
    case "Analytics":

      this.service.getAnalyticsSensorEventsByStudy(this.selectedStudy?.studyId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
        next: events => {

          this.chartData = new Array<ChartDataSets>();
          this.chartLabels = new Array<string>();

          var minData = new ChartData();
          var avgData = new ChartData();
          var maxData = new ChartData();

          minData.label = this.selectedSensor + ' (Min)';
          avgData.label = this.selectedSensor + ' (Average)';
          maxData.label = this.selectedSensor + ' (Max)';

          minData.data = new Array<number>();
          avgData.data = new Array<number>();
          maxData.data = new Array<number>();

          events.forEach(event => {

            if (event.min == null) {
              minData.data!.push(null);
            } else {
              minData.data!.push(Math.round((event.min! + Number.EPSILON) * 100) / 100);  
            }

            if (event.value == null) {
              avgData.data!.push(null);
            } else {
              avgData.data!.push(Math.round((event.value + Number.EPSILON) * 100) / 100);  
            }

            if (event.max == null) {
              maxData.data!.push(null);
            } else {
              maxData.data!.push(Math.round((event.max! + Number.EPSILON) * 100) / 100);  
            }

            this.chartLabels.push(event.label);
          });

          this.chartData.push(minData);
          this.chartData.push(avgData);
          this.chartData.push(maxData);

          this.loading = false;
        },
        error: err => {
          this.chartData = undefined;
          this.loading = false;
          this.openSnackBar("No Data On This Date Range", "Close");
        }
      });
      break;
    case "GPS":
      this.service.getGPSSensorEventsByStudy(this.selectedStudy?.studyId!, this.selectedSensor.toLowerCase(), this.startDate, this.endDate).subscribe({
        next: events => {
          this.geolocations=events;
          this.dataSource.data = events;
          this.loading = false;
        },
        error: err => {
          this.chartData = undefined;
          this.geolocations = undefined;
          this.loading = false;
          this.openSnackBar("No Data On This Date Range", "Close");
        }
      });
        break;
    case "Demographics":
      if(this.selectedResearcher.name == 'mindLAMP-JHU')
        this.isJHU = true;
      else
        this.isJHU = false;
      
      this.service.getDemographicsByStudy(this.selectedStudy?.studyId!, this.isJHU).subscribe({
        next: events => {
          this.demographicsList=events;
          this.loading = false;
        },
        error: err => {
          this.demographicsList = undefined;
          this.loading = false;
          this.openSnackBar("No Data On This Date Range", "Close");
        }
      });
        break;
    }
  }

  chartClicked(value) {

    if (value.active.length > 0) {

      let dialogRef = this.dialog.open(StudyEventsDetailComponent, {
        data: {
          study: this.selectedStudy,
          event: this.selectedSensor,
          start: this.startDate,
          end: this.endDate,
          index: value.active[0]._index
        }, disableClose: true, width: '105%', height: '95%', maxHeight: '95%'
      });

      dialogRef.afterClosed().subscribe();
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
