import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { share } from 'rxjs/operators';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

if (environment.production) {
  enableProdMode();
}

const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
let promise = httpClient.get('conf.json').pipe(share()).toPromise();
let clientId: any, authority: any, redirectURI: any, protectedResourceMapURI: any, protectedResourceMapSope: any;


export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(logLevel + ": " + message);
}

export function MSALInstanceFactory(): IPublicClientApplication {

  return new PublicClientApplication({
    auth: {
      clientId: clientId, 
      authority: authority, 
      redirectUri: redirectURI,
      postLogoutRedirectUri: 'http://mayoweb.mayo.edu/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(protectedResourceMapURI, [protectedResourceMapSope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

promise.then((config: any) => {
  clientId = config.clientID;
  authority = config.authority;
  redirectURI = config.redirectURI;
  protectedResourceMapURI = config.protectedResourceMapURI;
  protectedResourceMapSope = config.protectedResourceMapSope;

  platformBrowserDynamic([
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    }
  ]).bootstrapModule(AppModule)
    .catch(err => console.error(err));
})
