
import { formatDate } from '@angular/common';
import {AfterViewInit, Component, ViewChild, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Participant } from '../models/participant';
import { Researcher } from '../models/researcher';

import { Study } from '../models/study';
import { LampService } from '../services/lamp.service';
import { StudyTeamVisits } from '../models/studyTeamVisits';


@Component({
  selector: 'app-study-team-visits',
  templateUrl: './study-team-visits.component.html',
  styleUrls: ['./study-team-visits.component.scss']
})

export class StudyTeamVisitsComponent implements OnInit, AfterViewInit {
  researchers: Researcher[] | undefined;
  selectedResearcher: Researcher | undefined;
  studies: Study[] | undefined;
  selectedStudy: Study | undefined;
  participants: Participant[] | undefined;
  selectedParticipant: Participant | undefined;

  loading: boolean = false;
  isJHU : boolean | false;
  
  studyTeamVisitsDisplayedColumns = ['studyTeamVisitsType','baseline','month1','month2','month3'];
  studyTeamVisitsDataSource: MatTableDataSource<StudyTeamVisits> = new MatTableDataSource;
  studyTeamVisitsList: StudyTeamVisits[] | undefined;
  studyTeamVisitsShowTable: boolean = false;

  normalize: boolean = false;
  date: Date = new Date();

  @ViewChild('studyTeamVisitsSort') studyTeamVisitsSort: MatSort;

  
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.studyTeamVisitsDataSource.sort) {
      this.studyTeamVisitsDataSource.sort = sort;
    }
  }

  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar) { 
  }

  ngOnInit(): void {

    this.service.getResearchers().subscribe(researchers => {
      this.researchers = researchers; 
    });
  }

  ngAfterViewInit() {
    this.studyTeamVisitsDataSource.sort = this.studyTeamVisitsSort!;
  }

  researcherChanged() {

    this.service.getStudiesByResearcher(this.selectedResearcher?.researcherId!).subscribe(studies => {
      this.studies = studies;
    });

    this.selectedStudy = undefined;
    this.participants = undefined;
    this.selectedParticipant = undefined;
    this.resetData();
  }

  studyChanged() {
    this.participants = undefined;
    this.selectedParticipant = undefined;    
    this.service.getParticipantsByStudy(this.selectedStudy?.studyId!).subscribe(participants => {
      this.participants = participants;
    });
    this.resetData();
  }
  
  participantChanged() {
    this.resetData();
  }
  resetData() {
    this.studyTeamVisitsDataSource.data = [];
    this.studyTeamVisitsShowTable = false;
  }

  populateData() {
    this.generateTable();
  }

  generateTable() {
    var lastVal: number = -1;

    this.studyTeamVisitsDataSource.data = [];
    this.loading = true;

    this.studyTeamVisitsShowTable = false;

    if(this.selectedResearcher.name == 'mindLAMP-JHU')
      this.isJHU = true;
    else
      this.isJHU = false;
    
    this.service.getStudyTeamVisitsByParticipant(this.selectedStudy?.studyId!, this.selectedParticipant?.participantId!, this.selectedParticipant?.name!, this.isJHU).subscribe({
      next: events => {
        this.studyTeamVisitsList = events;
        console.log(JSON.stringify(events));
        console.log(JSON.stringify(this.studyTeamVisitsList));
        this.studyTeamVisitsDataSource.data = this.studyTeamVisitsList;
        this.studyTeamVisitsDataSource.sort = this.studyTeamVisitsSort!;
        this.loading = false;
        this.studyTeamVisitsShowTable = true;
      },
      error: err => {
        this.loading = false;
        this.studyTeamVisitsShowTable = false;
        this.openSnackBar("Error Loading Study Team Visits Data", "Close");
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
