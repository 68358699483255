import { formatDate } from '@angular/common';
import {AfterViewInit, Component, ViewChild, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Participant } from '../models/participant';
import { Researcher } from '../models/researcher';

import { Study } from '../models/study';
import { LampService } from '../services/lamp.service';
import { SleepSummary } from '../models/sleepsummary';

import { ChartDataSets } from 'chart.js';
import { ChartData } from '../models/chartData';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as pluginChartAnnotation from 'chartjs-plugin-annotation';
import { Label } from 'ng2-charts/lib/base-chart.directive';
import { Color } from 'ng2-charts/lib/color';

@Component({
  selector: 'app-sleep-log',
  templateUrl: './sleep-log.component.html',
  styleUrls: ['./sleep-log.component.scss']
})

export class SleepLogComponent implements OnInit, AfterViewInit {

  researchers: Researcher[] | undefined;
  selectedResearcher: Researcher | undefined;
  studies: Study[] | undefined;
  selectedStudy: Study | undefined;
  participants: Participant[] | undefined;
  selectedParticipant: Participant | undefined;

  loading: boolean = false;
  isJHU : boolean | false;

  sleepSummaryDisplayedColumns = ['eventDate','sleeplog_total_hours','me_wakeup_time','sleepsensor_wakeuptime','sleepsensor_source','sleepsensor_totaltime'];
  sleepSummaryDataSource: MatTableDataSource<SleepSummary> = new MatTableDataSource;
  sleepSummaryList: SleepSummary[] | undefined;
  sleepSummaryShowTable: boolean = false;

  normalize: boolean = false;
  date: Date = new Date();

  
  @ViewChild('sleepSummarySort') sleepSummarySort: MatSort;

  selectedSensorUnits: string = "";
  chartTypes: string[] = ["Bar", "Line"];
  selectedType: string = "Bar";
  chartData: ChartDataSets[] | undefined;
  chartLabels!: Label[];
  chartOptions = undefined;
  AllChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
         display: true,
         maxBarThickness: 70,
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          //labelString: 'Score'
        },
        ticks: {
          min: 0,
          beginAtZero: true
        }
      }]
    },
    elements: {
      line: {
        tension: 0,
        fill: false,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        clamp: true,
        color: 'black',
        font: {
          size: 12,
          weight: 'bold'
        },
        lineTension: 0,
        display: function (context) {
          return context.dataset.data[context.dataIndex] != null; 
        }
      }
    }
  };
  chartColors: Color[] = [];
  chartLegend = true;
  chartPlugins = [pluginDataLabels, pluginChartAnnotation,
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 20; // must use `function` and not => because of `this`
        };
      }
    }];

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    if (!this.sleepSummaryDataSource.sort) {
      this.sleepSummaryDataSource.sort = sort;
    }
  }
  constructor(private service: LampService, @Inject(LOCALE_ID) private locale: string, private snackBar: MatSnackBar) { 
    this.chartColors = service.getChartColors();
  }

  ngOnInit(): void {

    this.service.getResearchers().subscribe(researchers => {
      this.researchers = researchers; 
    });
  }

  ngAfterViewInit() {
    this.sleepSummaryDataSource.sort = this.sleepSummarySort!;
    this.sleepSummaryDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'eventDate': return new Date(item.eventDate);
        default: return item[property];
      }
    };    
  }

  researcherChanged() {

    this.service.getStudiesByResearcher(this.selectedResearcher?.researcherId!).subscribe(studies => {
      this.studies = studies;
    });

    this.selectedStudy = undefined;
    this.participants = undefined;
    this.selectedParticipant = undefined;
    this.resetData();
  }

  studyChanged() {
    this.participants = undefined;
    this.selectedParticipant = undefined;    
    this.service.getParticipantsByStudy(this.selectedStudy?.studyId!).subscribe(participants => {
      this.participants = participants;
    });
    this.resetData();
  }
  
  participantChanged() {
    this.resetData();
    this.selectedType="Bar";
  }
  resetData() {
    this.chartData = undefined;
    this.sleepSummaryDataSource.data = [];
    this.sleepSummaryShowTable = false;
  }

  chartTypeChanged() {
  }

  populateData() {
    this.generateTable();
    this.generateChart();
  }

  generateChart() {
    this.selectedSensorUnits="Hours";
    this.chartData = undefined;
    this.generateGraph();

  }
  generateGraph() {
    this.loading = true;
    this.chartData = undefined;
    this.chartOptions = this.AllChartOptions;
    if(this.selectedResearcher.name == 'mindLAMP-JHU')
    this.isJHU = true;
  else
    this.isJHU = false;
    this.service.getSleepSummaryForByParticipant(this.selectedParticipant?.participantId!, this.selectedParticipant?.name!, this.isJHU).subscribe({    
      next: events => {
        this.chartData = new Array<ChartDataSets>();
        this.chartLabels = new Array<string>();

        var sleepSensorData = new ChartData();
        var sleepLogData = new ChartData();

        sleepLogData.label =  "Log Data (Hours)";
        var sleepsensortotal = 0;
        var sleeplogtotal = 0;


        sleepSensorData.data = new Array<number>();
        sleepLogData.data = new Array<number>();

        events.forEach(event => {

          if (event.sleepsensor_total_hours == null || event.sleepsensor_total_hours == 0) {
            sleepSensorData.data!.push(null);
          } else {
            sleepSensorData.data!.push(Math.round((event.sleepsensor_total_hours + Number.EPSILON) * 100) / 100);  
            sleepsensortotal += event.sleepsensor_total_hours!;
          }
          if (event.sleeplog_total_hours == null || event.sleeplog_total_hours == 0) {
            sleepLogData.data!.push(null);
          } else {
            sleepLogData.data!.push(Math.round((event.sleeplog_total_hours + Number.EPSILON) * 100) / 100);  
            sleeplogtotal += event.sleeplog_total_hours!;
          }

          this.chartLabels.push(event.eventDate);
        });
        sleepsensortotal = Math.round((sleepsensortotal + Number.EPSILON) * 100) / 100;

        sleepSensorData.label = 'Sensor Data (Hours)';

        this.chartData.push(sleepSensorData);
        this.chartData.push(sleepLogData);

        this.loading = false;
      },
      error: err => {
        this.chartData = undefined;
        this.loading = false;
        this.openSnackBar("No Data On This Date Range", "Close");
      }
    });
  }
  generateTable() {
    var lastVal: number = -1;

    this.sleepSummaryDataSource.data = [];
    this.loading = true;

    this.sleepSummaryShowTable = false;

    if(this.selectedResearcher.name == 'mindLAMP-JHU')
      this.isJHU = true;
    else
      this.isJHU = false;
    
    this.service.getSleepSummaryForByParticipant(this.selectedParticipant?.participantId!, this.selectedParticipant?.name!, this.isJHU).subscribe({
      next: events => {
        this.sleepSummaryList = events;
        this.sleepSummaryDataSource.data = this.sleepSummaryList;
        this.sleepSummaryDataSource.sort = this.sleepSummarySort!;
        this.loading = false;
        this.sleepSummaryShowTable = true;
      },
      error: err => {
        this.loading = false;
        this.sleepSummaryShowTable = false;
        this.openSnackBar("Error Loading Sleep Summary Table Data", "Close");
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
